<template>
    <form @submit.prevent="action" v-if="selectedEmployer?.id || false">
        <div class="form-row">
            <div class="form-group col-md-4">
                <label>Name</label>
                <input type="text" class="form-control" placeholder="Type Name" name="name" v-model="form.memberName" />
            </div>
            <div class="form-group col-md-4">
                <label>Email</label>
                <input type="email" class="form-control" placeholder="Type Email" name="email" v-model="form.email" />
            </div>
            <div class="form-group col-md-4">
                <label>Staff Id</label>
                <input type="text" class="form-control" placeholder="Type Staff Id" name="staffid" v-model="form.staffid"
                    minlength="8" />
            </div>
            <div class="form-group col-md-4">
                <label>Role</label>
                <select class="form-control" name="role" v-model="form.role">
                    <option v-for="option in teamRoles" :key="option.id" :value="option.id">
                        {{ option.title || '' }}
                    </option>
                </select>
            </div>
            <div class="form-group col-md-4">
                <label>Department</label>
                <input type="text" class="form-control" placeholder="Type Department" name="department"
                    v-model="form.department" />
            </div>
        </div>
        <button type="submit" class="btn btn-common log-btn">+ Add</button>
    </form>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

    data: () => ({
        form: {
            memberName: '',
            email: '',
            staffid: '',
            role: '',
            department: '',
        },
    }),

    computed: {
        ...mapState("auth", ["currentUser"]),
        ...mapState("teamRole", ["teamRoles"]),
        ...mapState("employer", ["selectedEmployer"]),
    },

    mounted() {
        this.getAccountTeamRoles();
    },

    methods: {
        ...mapActions("employer", ["addEmployerTeamMember",]),
        ...mapActions("teamRole", ["getAccountTeamRoles"]),

        action() {
            if (this.currentUser.user.email == this.form.email) {
                this.$toast.error("You can't send invitation to your email.", {
                    position: 'top',
                });
                return;
            }

            if (this.selectedEmployer.id) {
                this.addEmployerTeamMember({
                    employerId: this.selectedEmployer.id,
                    memberEmail: this.form.email,
                    memberName: this.form.memberName,
                    staffid: this.form.staffid,
                    role: this.form.role,
                    department: this.form.department,
                }).then(() => {
                    this.$toast.success("Successfully Invitation Sent.", {
                        position: 'top',
                    });
                    this.clearForm();
                }).catch((err) => {
                    let msg = err.response.data.message || "Inviation sent failed, try again.";
                    this.$toast.error(msg, {
                        position: 'top',
                    });
                });
            }

        },

        clearForm() {
            this.form.email = '';
            this.form = {
                memberName: '',
                email: '',
                staffid: '',
                role: '',
                department: '',
            };
            this.memberAccess = [
                {
                    name: 'Opportunity',
                    module: 'opportunity',
                    create: true,
                    update: true,
                    delete: true,
                },
                {
                    name: 'Award',
                    module: 'award',
                    create: true,
                    update: true,
                    delete: true,
                },
                {
                    name: 'Announcement',
                    module: 'announcement',
                    create: true,
                    update: true,
                    delete: true,
                },
                {
                    name: 'Photo',
                    module: 'photo',
                    create: true,
                    update: true,
                    delete: true,
                },
                {
                    name: 'Certificate',
                    module: 'certificate',
                    create: true,
                    update: true,
                    delete: true,
                }
            ];
        },



    },
};
</script>
