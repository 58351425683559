<template>
    <div>
        <div class="card">
            <div class="card-body">
                <p class="card-title">Add Team Members</p>
                <br />
                <div class="card card-plain">
                    <add-team-member-form />
                </div>
            </div>
        </div>
        <div v-if="(employer.members || []).length > 0" class="card mt-3">
            <div class="card-body row">
                <div class="col-md-12">
                    <p class="card-title">Team Members</p>
                </div>
                <div v-for="member in employer.members || []" :key="member.id" class="col-md-4">
                    <div class="d-flex justify-content-between mt-3">
                        <div>
                            <h6 v-if="member.role?.title || false" class="text-dark">{{ member.role.title || '' }}</h6>
                            <p class="text-dark">{{ member.user.name || '' }}</p>
                            <span>{{ member.user.email || '' }}</span>
                        </div>
                        <div class="location mb-2">
                            <p class="text-danger" @click="() => removeTeamMember(member.id)">
                                <i class="far fa-trash-alt"></i> Delete
                            </p>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddTeamMemberForm from './AddTeamMemberForm.vue';

export default {

    components: {
        AddTeamMemberForm,
    },

    computed: {
        ...mapState("employer", ["employer"]),
    },


    methods: {
        ...mapActions("employer", ["removeEmployerTeamMember"]),

        removeTeamMember(teamMemberId) {
            if (confirm("are you sure you want to remove this member?")) {
                this.removeEmployerTeamMember({
                    employerId: this.$route.params.id,
                    teamMemberId,
                })
            }
        }
    }
};
</script>