<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/photos',
                    name: 'Photos',
                },
                {
                    route: '/account/photos',
                    name: 'Create Photo',
                },
            ]"
        />
        <photo-form :isEdit="false" />
    </div>
</template>

<script>
    import PhotoForm from "./components/PhotoForm.vue";

    export default {
        components: {
            PhotoForm,
        },
    };
</script>