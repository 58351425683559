<template>
    <div class="job-overview mb-5">
        <h3>Preview</h3>
        <ul class="d-flex">
            <li v-if="accounts.filter((e) => e.id == opportunity.employer_id).length > 0">
                <h3>Selected Employer Profile</h3>
                <span>
                    {{ accounts.filter((e) => e.id == opportunity.employer_id)[0].name || "" }}
                </span>
            </li>
            <li>
                <h3>Job Title</h3>
                <span>{{ opportunity.title || "" }}</span>
            </li>
            <li>
                <h3>Job Role</h3>
                <span>{{ opportunity.role || "" }}</span>
            </li>
            <li>
                <h3>Job Alias</h3>
                <span>{{ opportunity.alias || "" }}</span>
            </li>
        </ul>
        <ul class="d-flex mt-3" v-if="opportunity.description.description || false">
            <li>
                <h3>Description</h3>
                <div v-html="opportunity.description.description || ''"></div>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <h3>Gender</h3>
                <span>{{ opportunity.gender || "" }}</span>
            </li>
            <li>
                <h3>Age</h3>
                <span>
                    {{ opportunity.age || '' }}
                    {{ (opportunity.age_end || false) ? ' - ' : '' }}
                    {{ opportunity.age_end || '' }}
                </span>
            </li>
            <li>
                <h3>Nationality</h3>
                <span>{{ opportunity.info?.nationality || "" }}</span>
            </li>
            <li>
                <h3>Languages</h3>
                <span>{{ opportunity.additional?.languages || "" }}</span>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <h3>Experience</h3>
                <span>{{ opportunity.negotiation?.experience || "" }}</span>
            </li>
            <li>
                <h3>Qualification</h3>
                <span>{{ opportunity.negotiation?.qualification || "" }}</span>
            </li>
            <li>
                <h3>Certification</h3>
                <span>{{ opportunity.negotiation?.certification || "" }}</span>
            </li>
            <li>
                <h3>Skills</h3>
                <span>{{ opportunity.additional?.skills || "" }}</span>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <div class="row mt-4">
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.educational_certificates ?? false" />
                            <label class="form-check-label" for="">Educational Certificates</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.employment_certificates ?? false" />
                            <label class="form-check-label" for="">Employment Certificates</label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.reference_letter ?? false" />
                            <label class="form-check-label" for="">Reference Letter</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.identity_card ?? false" />
                            <label class="form-check-label" for="">Identity Card</label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.passport ?? false" />
                            <label class="form-check-label" for="">Passport</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.police_report ?? false" />
                            <label class="form-check-label" for="">Police Report</label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.photograph ?? false" />
                            <label class="form-check-label" for="">Photograph</label>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" class="form-check-input" id="" disabled
                                :checked="opportunity.additional?.cv ?? false" />
                            <label class="form-check-label" for="">CV</label>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <div class="form-group">
                    <input type="checkbox" class="form-check-input" id="" disabled
                        :checked="opportunity.additional?.people_with_disabilities ?? false" />
                    <label class="form-check-label" for="">This job is targeted to people with disabilities</label>
                </div>
            </li>
            <li>
                <div class="form-group">
                    <input type="checkbox" class="form-check-input" id="" disabled
                        :checked="opportunity.additional?.moms_working_from_home ?? false" />
                    <label class="form-check-label" for="">This job is targeted to Moms working from home</label>
                </div>
            </li>
            <li>
                <div class="form-group">
                    <input type="checkbox" class="form-check-input" id="" disabled
                        :checked="opportunity.additional?.volunteer_experience ?? false" />
                    <label class="form-check-label" for="">Volunteer Experience</label>
                </div>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <h3>Pay Period</h3>
                <span>{{ opportunity.negotiation?.pay_period || "" }}</span>
            </li>
            <li>
                <h3>Salary</h3>
                <span v-if="(opportunity.negotiation?.salary || '') == 'Show'">
                    ({{ opportunity.negotiation?.salary_min || "" }}
                    -
                    {{ opportunity.negotiation?.salary_max || "" }})
                    {{ opportunity.negotiation?.currency || "" }}
                </span>
                <span v-else>
                    {{ opportunity.negotiation?.salary || "" }}
                </span>
            </li>
            <li>
                <h3>Benefits</h3>
                <span>{{ opportunity.negotiation?.benefit_offers || "" }}</span>
            </li>
            <li>
                <div class="form-group">
                    <input type="checkbox" class="form-check-input" id="" disabled
                        :checked="opportunity.negotiation?.negotiable ?? false" />
                    <label class="form-check-label" for="">Negotiable</label>
                </div>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <h3>Employment Type</h3>
                <span>{{ opportunity.info?.employment_type || "" }}</span>
            </li>
            <li>
                <h3>Career Level</h3>
                <span>{{ opportunity.info?.career_level || "" }}</span>
            </li>
            <li>
                <h3>Workplace</h3>
                <span>{{ opportunity.info?.work_place || "" }}</span>
            </li>
            <li>
                <h3>Workshift</h3>
                <span>{{ opportunity.info?.work_shift || "" }}</span>
            </li>
            <li>
                <h3>Industry</h3>
                <span>{{ opportunity.info?.industry || "" }}</span>
            </li>
        </ul>
        <ul class="d-flex mt-3">
            <li>
                <h3>Apply Option</h3>
                <span v-if="(opportunity.additional?.apply_option || '') == 'email'">
                    Email: {{ opportunity.additional?.apply_option_email || "" }}
                </span>
                <span v-else-if="(opportunity.additional?.apply_option || '') == 'external'">
                    Url:
                    <a :href="opportunity.additional?.apply_option_url || '#'">
                        {{ opportunity.additional?.apply_option_url || "" }}
                    </a>
                </span>
                <span v-else>{{ opportunity.additional?.apply_option || "" }}</span>
            </li>
            <li>
                <h3>Due Date</h3>
                <span class="text-danger" v-if="opportunity.due_date || false">
                    {{
                        new Date(opportunity.due_date || "").toLocaleString(
                            "en-US",
                            {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            }
                        )
                    }}
                </span>
                <span v-else>
                    No Due Date Found 
                </span>
            </li>
            <li v-if="opportunity.publish_date || false">
                <h3>Published Date</h3>
                <span class="text-danger" v-if="opportunity.publish_date || false">
                    {{
                        new Date(opportunity.publish_date || "").toLocaleString(
                            "en-US",
                            {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            }
                        )
                    }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("opportunity", ["opportunity", "selectedEmployerId"]),
        ...mapState("employer", ["accounts"]),
    },
}
</script>
