<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/awards',
                    name: 'Awards',
                },
                {
                    route: '/account/awards',
                    name: 'Create Award',
                },
            ]"
        />
        <award-form :isEdit="false" />
    </div>
</template>

<script>
    import AwardForm from "./components/AwardForm.vue";

    export default {
        components: {
            AwardForm,
        },
    };
</script>