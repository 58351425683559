import Auth from "../../../services/apis/auth";
import {
    getAuth, createUserWithEmailAndPassword,
    signInWithEmailAndPassword, signInWithPopup, signInWithCustomToken,
    GoogleAuthProvider,
} from "firebase/auth";

const state = () => ({
    currentUser: {
        user: JSON.parse(localStorage.getItem("user")) || {},
        token: localStorage.getItem("token") || "",
    },
});

const actions = {
    login: ({ dispatch }, user) => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, user.email, user.password)
                .then((userCredential) => {
                    Auth.setAuthHeader(userCredential.user.accessToken);
                    Auth.tokenlogin().then(async (response) => {
                        await dispatch("customTokenLogin", response);
                        resolve(response);
                    }).catch(error => {
                        console.log(error)
                        reject(error.response.data.message || error.message);
                    });
                })
                .catch((error) => {
                    reject(error.message);
                });
        });
    },

    googleLogin: ({ dispatch }) => {
        return new Promise((resolve, reject) => {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            signInWithPopup(auth, provider)
                .then((result) => {
                    Auth.setAuthHeader(result.user.accessToken);
                    Auth.tokenlogin()
                        .then(async (response) => {
                            await dispatch("customTokenLogin", response);
                            resolve(response);
                        })
                        .catch((error) => {
                            reject(error.response.data.message);
                        });
                }).catch((error) => {
                    reject(error.message);
                });
        });
    },

    register: ({ dispatch }, user) => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, user.email, user.password)
                .then((userCredential) => {
                    Auth.setAuthHeader(userCredential.user.accessToken);
                    Auth.tokenlogin(user).then(async (response) => {
                        await dispatch("customTokenLogin", response);
                        resolve(response);
                    }).catch(error => {
                        reject(error.response.data.message);
                    });
                })
                .catch((error) => {
                    reject(error.message);
                });
        });
    },

    logout: ({ commit }) => {
        Auth.logout();
        commit('USER_LOGOUT');
    },

    customTokenLogin: ({ commit }, response) => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            console.log('response', response);
            console.log('response', response.data.token);
            signInWithCustomToken(auth, response.data.token)
                .then((userCredential) => {
                    Auth.setToken(userCredential.user.accessToken);
                    Auth.setUser(JSON.stringify(response.data.user));
                    commit('SET_USER', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error.message);
                });
        });
    },
};

const getters = {};

const mutations = {
    SET_USER: (state, user) => {
        state.currentUser = user;
    },

    USER_LOGOUT: (state) => {
        state.currentUser = {};
    }
};


export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
};
