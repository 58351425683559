<template>
    <div class="blogpost style2 min-w-100 bg-white">
        <div v-if="isAccount || false" class="float-right">
            <router-link
                :to="{
                    name: 'account-announcement-edit',
                    params: { id: announcement.id },
                }"
                ><div class="color-primary"><i class="fas fa-edit"></i></div
            ></router-link>
            <div @click="destroy(announcement.id)" class="text-danger">
                <i class="fas fa-trash"></i>
            </div>
        </div>
        <div class="blog-postdetail mt-4">
            <ul class="post-metas mb-2">
                <li>
                    <a href="#" title=""><i class="la la-calendar-o"></i>{{ announcement.created_at }}</a>
                </li>
            </ul>
            <span>
                <a class="project-aanoun" href="#" title="">{{ announcement.title }}</a>
                <p>
                    {{ announcement.description }}
                </p>
                <a class="bbutton" :href="announcement.file?.path || '#'" target="_blank">
                    <i class="fas fa-file-pdf"></i> View File
                    <i class="fas fa-long-arrow-alt-right"></i>
                </a>
                <ul v-if="isAccount || false" class="post-metas mb-2">
                    <li v-if="announcement.expired_date || false">
                        Expired Date:
                        <a href="#" title=""><i class="la la-calendar-o"></i>{{ announcement.expired_date }}</a>
                    </li>
                </ul>
            </span>
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["announcement", "isAccount"],

        methods: {
            ...mapActions("announcement", [
                "getAccountAnnouncements",
                "destroyAnnouncement",
            ]),

            destroy(id) {
                this.destroyAnnouncement(id).then(() => {
                    this.getAccountAnnouncements();
                    this.successToast();
                }).catch(() => this.errorToast());
            },
        },
    };
</script>
