<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/announcements',
                name: 'Announcements',
            },
        ]" />
        <div v-if="selectedEmployer || false">
            <div class="row align-items-center">
                <div class="col-md-12 text-right">
                    <router-link :to="{ name: 'account-announcement-create' }"
                        class="btn btn-common btn-effect wow fadeInRight mt-3" data-wow-delay="0.9s">Create</router-link>
                </div>
            </div>
            <div v-if="arrangeAnnouncements.length || false" class="row mx-3 mb-5 mt-3">
                <announcement-card v-for="announcement in arrangeAnnouncements" :key="announcement.id"
                    :announcement="announcement" :isAccount="true" />
            </div>
            <div v-else class="row justify-content-center mb-5 mt-3">
                <empty :title="arrangeAnnouncementsText" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";
import AnnouncementCard from "@/components/employers/AnnouncementCard.vue";

export default {
    components: {
        Empty,
        AnnouncementCard,
    },

    computed: {
        ...mapState("employer", ["selectedEmployer"]),
        ...mapState("announcement", ["arrangeAnnouncements", "arrangeAnnouncementsText"]),
    },

    mounted() {
        if (this.selectedEmployer || false) {
            this.initAccountData(this.selectedEmployer.id);
        }
    },

    methods: {
        ...mapActions("announcement", [
            "initAccountData",
        ]),
    },
};
</script>