<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/employer',
                    name: 'Employers',
                },
                {
                    route: '/account/employer',
                    name: 'Edit Employers',
                },
            ]"
        />
        <employer-form :isEdit="true" />
    </div>
</template>


<script>
    import { mapActions } from "vuex";
    import EmployerForm from "./components/EmployerForm.vue";

    export default {
        components: {
            EmployerForm,
        },

        mounted() {
            this.getAccountEmployerById(this.$route.params.id).then(data => {
                this.getIslandsByAtollName(data.atoll);
            });
        },

        methods: {
            ...mapActions("employer", ["getAccountEmployerById"]),
            ...mapActions("optionProperty", [
                "getIslandsByAtollName",
            ]),
        },
    };
</script>