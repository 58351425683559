<template>
    <div v-if="laoding" class="card box">
        <p>Loading...</p>
    </div>
    <div v-else-if="accounts.length ?? false">
        <div v-if="opportunity.published || false" class="card job-overview p-0 mb-2">
            <div class="">
                <ul class="d-flex">
                    <li v-if="opportunity.due_date || false">
                        <h3>Due Date</h3>
                        <span class="text-danger">
                            {{
                                new Date(opportunity.due_date || "").toLocaleString(
                                    "en-US",
                                    {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    }
                                )
                            }}
                        </span>
                    </li>
                    <li v-if="opportunity.publish_date || false">
                        <h3>Published Date</h3>
                        <span class="text-danger">
                            {{
                                new Date(opportunity.publish_date || "").toLocaleString(
                                    "en-US",
                                    {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    }
                                )
                            }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <form @submit.prevent="action" id="opportunityForm">
            <div class="row mx-1 card box">
                <div class="col-12 mb-3">
                    <div class="d-flex justify-content-end">
                        <div v-if="!isEdit && !showJobboards">
                            <router-link to="/account/opportunities/templates" class="btn btn-primary mx-2">
                                Choose From Templates
                            </router-link>
                        </div>
                        <div v-if="!showJobboards">
                            <button v-if="!(isEdit || false)" type="button" @click="saveAsTemplateOpportunity"
                                class="btn btn-border" :class="{ 'opacity-25': opportunity.processing }"
                                :disabled="opportunity.processing">
                                Save As Template
                            </button>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-primary" :class="{ 'opacity-25': opportunity.processing }"
                                @click="toggleShowJobboards">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
                <div v-show="!showJobboards">
                    <form-wizard @on-complete="onFormComplete" finishButtonText="Preview" shape="tab" color="#8db750"
                        :disableBackOnClickStep="true">
                        <tab-content title="General" :before-change="() => beforeTabSwitch('general')">
                            <opportunity-general-form :is-edit="isEdit || false" />
                        </tab-content>
                        <tab-content title="Requirements" :before-change="() => beforeTabSwitch('requirement')">
                            <opportunity-requirement-form :is-edit="isEdit || false" />
                        </tab-content>
                        <tab-content title="Documents" :before-change="() => beforeTabSwitch('document')">
                            <opportunity-document-form :is-edit="isEdit || false" />
                        </tab-content>
                        <tab-content title="Negotiate" :before-change="() => beforeTabSwitch('negotiate')">
                            <opportunity-negotiate-form :is-edit="isEdit || false" />
                        </tab-content>
                        <tab-content title="Info" :before-change="() => beforeTabSwitch('info')">
                            <opportunity-info-form :is-edit="isEdit || false" />
                        </tab-content>
                        <tab-content title="Others" :before-change="() => beforeTabSwitch('other')">
                            <opportunity-other-form :is-edit="isEdit || false" />
                        </tab-content>
                    </form-wizard>
                </div>
                <opportunity-publish-jobboards v-if="showJobboards" class="col-12">
                    <template #content>
                        <div class="d-flex my-5">
                            <button v-if="!(opportunity.published || false)" type="button" @click="publishOpportunity"
                                class="btn btn-border mr-2" :class="{ 'opacity-25': opportunity.processing }"
                                :disabled="opportunity.processing">
                                {{ jobPublishBtnText }}
                            </button>
                            <button v-if="!(opportunity.published || false) && (jobPublishBtnText != 'Re Publish')"
                                type="submit" class="btn btn-primary log-btn mr-2 opprtunitysubmitbtn"
                                :class="{ 'opacity-25': opportunity.processing }">
                                {{ isEdit || false ? "Update" : "Save" }}
                            </button>
                            <button v-if="!(isEdit || false)" type="button" @click="saveAsTemplateOpportunity"
                                class="btn btn-info" :class="{ 'opacity-25': opportunity.processing }">
                                Save As Template
                            </button>
                            <button v-if="!(isEdit || false)" type="button" @click="saveAsGlobalTemplateOpportunity"
                                class="btn btn-warning mx-2" :class="{ 'opacity-25': opportunity.processing }">
                                Save As Global Template
                            </button>
                        </div>
                    </template>
                </opportunity-publish-jobboards>
            </div>
        </form>
    </div>
    <div v-else class="col-md-12 mb-5 mt-5 pt-5">
        <empty title="Please create employer profile frist!" />
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<script>
import Empty from "@/components/utils/Empty.vue";
import OpportunityMixin from '@/mixins/OpportunityMixin.js';

import { FormWizard, TabContent } from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

import OpportunityGeneralForm from './form/OpportunityGeneralForm.vue';
import OpportunityRequirementForm from './form/OpportunityRequirementForm.vue';
import OpportunityDocumentForm from './form/OpportunityDocumentForm.vue';
import OpportunityNegotiateForm from './form/OpportunityNegotiateForm.vue';
import OpportunityInfoForm from './form/OpportunityInfoForm.vue';
import OpportunityOtherForm from './form/OpportunityOtherForm.vue';

import OpportunityPublishJobboards from './OpportunityPublishJobboards.vue';

export default {
    props: {
        isEdit: Boolean,
    },

    components: {
        Empty,
        FormWizard,
        TabContent,
        OpportunityGeneralForm,
        OpportunityRequirementForm,
        OpportunityDocumentForm,
        OpportunityNegotiateForm,
        OpportunityInfoForm,
        OpportunityOtherForm,
        OpportunityPublishJobboards,
    },

    mixins: [OpportunityMixin],

};
</script>
