import Announcement from "../../../../services/apis/announcement";

const state = () => ({
    employerId: null,
    previewfile: null,
    announcements: [],
    arrangeAnnouncements: [],
    arrangeAnnouncementsText: 'Please Select Employer Profile',
    announcement: {
        id: "",
        user_id: "",
        employer_id: "",
        file_id: "",
        title: "",
        description: "",
        expired_date: "",
        status: "",
        file: null,
    },
});

const actions = {
    initAccountData: ({ dispatch, commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        dispatch("getAccountAnnouncements");
    },

    createAnnouncement: ({ commit }, announcement) => {
        Announcement.store(announcement).then(() => {
            console.log(announcement)
            commit("CLEAR_ANNOUNCEMENT");
        });
    },

    getAccountAnnouncements: ({ commit, state }) => {
        Announcement.accounts().then((response) => {
            commit("SET_ANNOUNCEMENTS", response.data);
            if (state.employerId != null) {
                commit('GET_ANNOUNCEMENTS_BY_EMPLOYER_ID', state.employerId);
            }
        });
    },

    getAnnouncementsByEmployerId: ({ commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        commit('GET_ANNOUNCEMENTS_BY_EMPLOYER_ID', empId);
    },

    getAnnouncementById: ({ commit }, id) => {
        Announcement.find(id).then((response) => {
            commit("SET_ANNOUNCEMENT", response.data);
        });
    },

    updateAnnouncement: ({ commit }, announcement) => {
        Announcement.update(announcement).then(() => {
            commit("CLEAR_ANNOUNCEMENT");
        });
    },

    destroyAnnouncement: (_, id) => {
        const txt = "Are you sure, you want to delete this announcement?";
        if (confirm(txt)) {
            Announcement.destroy(id);
        }
    },

    onFileChanged: ({ commit }, event) => {
        commit("SET_SELECTED_FILE", event);
    },

    updateAnnouncementInput({ commit }, event) {
        commit('UPDATE_ANNOUNCEMENT_INPUT', event);
    },

    clearAnnouncementForm: ({ commit }, empId) => {
        commit("CLEAR_ANNOUNCEMENT", empId);
    }
};

const getters = {};

const mutations = {
    SET_ANNOUNCEMENT: (state, announcement) => {
        state.announcement = announcement;
        state.previewfile = state.announcement.file;
        state.announcement.file = null;
    },

    SET_ANNOUNCEMENTS: (state, announcements) => {
        state.announcements = announcements;
    },

    SET_SELECTED_FILE: (state, event) => {
        state.announcement.file = event.target.files[0];
    },

    CLEAR_ANNOUNCEMENT: (state, empId) => {
        state.announcement = {
            id: "",
            user_id: "",
            employer_id: empId || "",
            file_id: "",
            title: "",
            description: "",
            expired_date: "",
            status: "",
            file: null,
        };
        state.arrangeAnnouncements = [];
    },

    UPDATE_ANNOUNCEMENT_INPUT: (state, event) => {
        let temp = state.announcement;
        temp[event.target.name] = event.target.value;
        state.announcement = temp;
    },

    GET_ANNOUNCEMENTS_BY_EMPLOYER_ID: (state, employerId) => {
        state.arrangeAnnouncements = [];
        state.arrangeAnnouncements = state.announcements.filter(item => item.employer_id == employerId);
        state.arrangeAnnouncementsText = state.arrangeAnnouncements.length ? '' : '0 Announcement Found!';
    },

    SET_EMPLOYER_ID: (state, id) => {
        state.employerId = id;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
