import Contact from "../../../services/apis/contact";

const actions = {
    contact: (_, data) => {
        Contact.store(data);
    }
}

export default {
    namespaced: true,
    actions,
};
