<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/photos',
                name: 'Photos',
            },
        ]" />
        <div v-if="selectedEmployer || false">
            <div class="row align-items-center">
                <div class="col-md-12 text-right">
                    <router-link :to="{ name: 'account-photo-create' }"
                        class="btn btn-common btn-effect wow fadeInRight mt-3" data-wow-delay="0.9s">
                        Create
                    </router-link>
                </div>
            </div>
            <div v-if="arrangePhotos.length || false" class="row mx-3 mb-5 mt-3">
                <div class="row min-w-100">
                    <photo-card v-for="photo in arrangePhotos" :key="photo.id" :photo="photo" />
                </div>
            </div>
            <div v-else class="row justify-content-center mb-5 mt-3">
                <empty :title="arrangePhotosText" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";
import PhotoCard from "@/components/employers/PhotoCard.vue";

export default {
    components: {
        Empty,
        PhotoCard,
    },

    computed: {
        ...mapState("employer", ["selectedEmployer"]),
        ...mapState("photo", ["arrangePhotos", "arrangePhotosText"]),
    },

    mounted() {
        if (this.selectedEmployer || false) {
            this.initAccountData(this.selectedEmployer.id);
        }
    },

    methods: {
        ...mapActions("photo", [
            "initAccountData",
        ]),
    },
};
</script>