import Award from "../../../../services/apis/award";

const state = () => ({
    employerId: null,
    previewfile: null,
    awards: [],
    arrangeAwards: {},
    arrangeAwardsText: 'Please Select Employer Profile',
    award: {
        id: '',
        user_id: '',
        employer_id: '',
        file_id: '',
        title: '',
        description: '',
        date: '',
        status: '',
        file: null,
    },
});

const actions = {
    initAccountData: ({ dispatch, commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        dispatch("getAccountAwards");
    },

    createAward: ({ commit }, award) => {
        Award.store(award).then(() => {
            commit("CLEAR_AWARD");
        });
    },

    getAccountAwards: ({ commit, state }) => {
        Award.accounts().then((response) => {
            commit("SET_AWARDS", response.data);
            if (state.employerId != null) {
                commit('GET_AWARDS_BY_EMPLOYER_ID', state.employerId);
            }
        });
    },

    getAwardsByEmployerId: ({ commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        commit('GET_AWARDS_BY_EMPLOYER_ID', empId);
    },

    getAwardById: ({ commit }, id) => {
        Award.find(id).then((response) => {
            commit("SET_AWARD", response.data);
        });
    },

    updateAward: ({ commit }, award) => {
        Award.update(award).then(() => {
            commit("CLEAR_AWARD");
        });
    },

    destroyAward: (_, id) => {
        const txt = "Are you sure, you want to delete this award?";
        if (confirm(txt)) {
            Award.destroy(id);
        }
    },

    onFileChanged: ({ commit }, event) => {
        commit("SET_SELECTED_FILE", event);
    },

    updateAwardInput({ commit }, event) {
        commit('UPDATE_AWARD_INPUT', event);
    },

    clearAwardForm: ({ commit }, empId) => {
        commit("CLEAR_AWARD", empId);
    }
};

const getters = {};

const mutations = {
    SET_AWARD: (state, award) => {
        state.award = award;
        state.previewfile = state.award.file;
        state.award.file = null;
    },

    SET_AWARDS: (state, awards) => {
        state.awards = awards;
    },

    SET_SELECTED_FILE: (state, event) => {
        state.award.file = event.target.files[0];
    },

    CLEAR_AWARD: (state, empId) => {
        state.award = {
            id: '',
            user_id: '',
            employer_id: empId || '',
            file_id: '',
            title: '',
            description: '',
            date: '',
            status: '',
            file: null,
        };
        state.previewfile = null;
        state.arrangeAwards = [];
    },

    UPDATE_AWARD_INPUT: (state, event) => {
        let temp = state.award;
        temp[event.target.name] = event.target.value;
        state.award = temp;
    },

    GET_AWARDS_BY_EMPLOYER_ID: (state, employerId) => {
        state.arrangeAwards = [];
        state.arrangeAwards = state.awards.filter(item => item.employer_id == employerId);
        state.arrangeAwardsText = state.arrangeAwards.length ? '' : '0 Award Found!';
    },

    SET_EMPLOYER_ID: (state, id) => {
        state.employerId = id;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
