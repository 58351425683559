import Employer from "../../../services/apis/employer";

const state = () => ({
    previewfile: null,
    accounts: {},
    employers: {},
    employer: {
        id: "",
        file_id: "",
        category_id: "",
        slug: "",
        name: "",
        email: "",
        phone: "",
        website: "",
        description: "",
        address: "",
        atoll: "",
        island: "",
        employment_types: [],
        employment_sector: "",
        industries: [],
        ownership_type: "",
        since: "",
        affiliations: "",
        team_size: "",
        office_start_hour: "",
        office_end_hour: "",
        currency: "",
        file: null,
    },
    singleEmployer: {},
    totalEmployers: '',
    totalOpportunities: '',
    userLocations: [],
    selectedEmployer: null,
});

const actions = {

    getEmployers: ({ commit }, page) => {
        Employer.all(page).then((response) => {
            commit("SET_EMPLOYERS", response.data);
        });
    },

    countEmployers: ({ commit }) => {
        Employer.count().then((response) => {
            commit("COUNT_EMPLOYERS", response.data);
        });
    },

    filterEmployers: ({ commit }, filter) => {
        Employer.filter(filter ?? false).then((response) => {
            commit("SET_EMPLOYERS", response.data);
        });
    },

    alphaFilterEmployers: ({ commit }, alpha) => {
        Employer.alphaFilter(alpha ?? '').then((response) => {
            commit("SET_EMPLOYERS", response.data);
        });
    },

    createEmployer: async ({ commit }, employer) => {
        return Employer.store(employer).then((response) => {
            if (response.statusCode == 201) {
                commit("CLEAR_EMPLOYER");
            }
        });
    },

    getAccountEmployers: ({ commit }) => {
        return Employer.accounts().then((response) => {
            commit("SET_ACCOUNT_EMPLOYERS", response.data);
        });
    },

    getEmployerById: ({ commit }, id) => {
        Employer.find(id).then((response) => {
            commit("SET_EMPLOYER", response.data);
        });
    },

    getAccountEmployerById: ({ commit }, id) => {
        return Employer.find(id).then((response) => {
            commit("SET_ACCOUNT_EMPLOYER", response.data);
            return response.data;
        });
    },

    updateEmployer: async ({ commit }, employer) => {
        return Employer.update(employer).then((response) => {
            if (response.statusCode == 201) {
                commit("CLEAR_EMPLOYER");
            }
        });
    },

    destroyEmployer: (_, id) => {
        const txt = "Are you sure, you want to delete this employer profile?";
        if (confirm(txt)) {
            Employer.destroy(id);
        }
    },

    onFileChanged: ({ commit }, event) => {
        commit("SET_SELECTED_FILE", event);
    },

    updateEmployerInput: ({ commit }, event) => {
        commit('UPDATE_EMPLOYER_INPUT', event);
    },

    updateEmployerMultipleSelect: ({ commit }, { key, value }) => {
        commit('UPDATE_EMPLOYER_MULTIPLE_SELECT', { key, value });
    },

    updateEmployerDescriptionInput: ({ commit }, value) => {
        commit('UPDATE_EMPLOYER_DESCRIPTION_INPUT', value);
    },

    clearOldIslandValue: ({ commit }) => {
        commit('CLEAR_ISLAND_INPUT');
    },

    clearEmployerProfileForm: ({ commit }) => {
        commit("CLEAR_EMPLOYER");
    },

    createUserLocation: (_, data) => Employer.storeUserLocation(data),

    getUserLocations: ({ commit }) => {
        Employer.getUserLocations().then(response => {
            commit("SET_USER_LOCATIONS", response.data);
        })
    },

    addEmployerTeamMember: async ({ dispatch }, data) => {
        return Employer.addEmployerTeamMember(data).then(async (response) => {
            await dispatch("getAccountEmployerById", data.employerId);
            return response;
        });
    },

    removeEmployerTeamMember: async ({ dispatch }, { employerId, teamMemberId }) => {
        return Employer.removeEmployerTeamMember({ teamMemberId }).then(async (response) => {
            await dispatch("getAccountEmployerById", employerId);
            return response;
        });
    },

    onSelectGlobalEmployer: ({ commit, state, dispatch }, empId) => {
        const data = state.accounts.filter((emp) => emp.id == empId);
        if (data.length) {
            commit("SET_GLOBAL_EMPLOYER", data[0]);
            dispatch("opportunity/initAccountData", data[0].id, { root: true })
            dispatch("announcement/initAccountData", data[0].id, { root: true })
            dispatch("award/initAccountData", data[0].id, { root: true })
            dispatch("photo/initAccountData", data[0].id, { root: true })
            dispatch("certificate/initAccountData", data[0].id, { root: true })
            dispatch("teamRole/initAccountData", data[0].id, { root: true })
            dispatch("getAccountEmployerById", empId);
        }
    },
};

const getters = {};

const mutations = {
    SET_EMPLOYER: (state, employer) => {
        state.singleEmployer = employer;
        state.previewfile = state.employer.file;
    },

    SET_EMPLOYERS: (state, employers) => {
        state.employers = employers;
    },

    SET_ACCOUNT_EMPLOYER: (state, employer) => {
        state.employer = employer;
        state.previewfile = state.employer.file;
    },

    SET_ACCOUNT_EMPLOYERS: (state, employers) => {
        state.accounts = employers;
    },

    SET_SELECTED_FILE: (state, event) => {
        state.employer.file = event.target.files[0];
    },

    UPDATE_EMPLOYER_INPUT: (state, event) => {
        let temp = state.employer;
        temp[event.target.name] = event.target.value;
        state.employer = temp;
    },

    CLEAR_EMPLOYER: (state) => {
        state.employer = {
            id: "",
            file_id: "",
            category_id: "",
            slug: "",
            name: "",
            email: "",
            phone: "",
            website: "",
            description: "",
            address: "",
            atoll: "",
            island: "",
            employment_types: [],
            employment_sector: "",
            industries: [],
            ownership_type: "",
            since: "",
            affiliations: "",
            team_size: "",
            office_start_hour: "",
            office_end_hour: "",
            currency: "",
            file: null,
        };
        state.previewfile = null;
    },

    UPDATE_EMPLOYER_MULTIPLE_SELECT: (state, { key, value }) => {
        state.employer[key] = value;
    },

    UPDATE_EMPLOYER_DESCRIPTION_INPUT: (state, value) => {
        state.employer.description = value;
    },

    CLEAR_ISLAND_INPUT: (state) => {
        state.employer.island = '';
    },

    COUNT_EMPLOYERS: (state, value) => {
        state.totalEmployers = value.total_employers;
        state.totalOpportunities = value.total_opportunities;
    },

    SET_USER_LOCATIONS: (state, data) => {
        state.userLocations = data;
    },

    SET_GLOBAL_EMPLOYER: (state, data) => {
        state.selectedEmployer = data;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};

