<template>
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="form-group">
                <label>Salary</label>
                <select class="form-control" name="negotiation.salary" :value="opportunity.negotiation?.salary ?? ''"
                    @input="handleOpportunityInput">
                    <option value="" selected disabled>Select</option>
                    <option value="Show">Show</option>
                    <option value="Not Stated">Not Stated</option>
                </select>
            </div>
            <div class="form-group ml-3">
                <input type="checkbox" class="form-check-input" id="Negotiable" name="negotiation.negotiable"
                    :value="opportunity.negotiation?.negotiable ?? ''"
                    :checked="opportunity.negotiation?.negotiable ?? false" @input="handleOpportunityInput" />
                <label class="form-check-label" for="Negotiable">Negotiable</label>
            </div>
        </div>
        <div v-if="opportunity.negotiation?.salary == 'Show'" class="col-md-6">
            <div class="form-group">
                <label>min/max</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Min" name="negotiation.salary_min"
                        :value="opportunity.negotiation?.salary_min ?? ''" @input="handleOpportunityInput" />
                    <input type="text" class="form-control" placeholder="max" name="negotiation.salary_max"
                        :value="opportunity.negotiation?.salary_max ?? ''" @input="handleOpportunityInput" />
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Currency</label>
                <select class="form-control" name="negotiation.currency" :value="opportunity.negotiation?.currency ?? ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.currency" :key="option.id" :value="option.currency">
                        {{ option.currency }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Pay Period</label>
                <select class="form-control" name="negotiation.pay_period"
                    :value="opportunity.negotiation?.pay_period ?? ''" @input="handleOpportunityInput">
                    <option v-for="option in options.pay_period" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Benefit Offer</label>
                <v-select label="title" v-model="handleBenefitOffer" :options="options.benefit_offer || []"
                    :reduce="(option) => option?.title || ''" class="form-control" multiple></v-select>
            </div>
        </div>
    </div>
</template>


<script>
import OpportunityMixin from '@/mixins/OpportunityMixin.js';
import vSelect from 'vue-select';

export default {
    props: {
        isEdit: Boolean,
    },

    components: {
        vSelect,
    },

    mixins: [OpportunityMixin],

};
</script>