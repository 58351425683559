import Api from "./api.js";

const END_POINT = '/categories';

export default {

    popular() {
        return Api.get(END_POINT + '/popular');
    },

}