import AccountAnnounecments from './../../views/account/announcement/Index.vue';
import CreateAnnounecment from './../../views/account/announcement/Create.vue';
import EditAnnounecment from './../../views/account/announcement/Edit.vue';

const routes = [
    {
        path: '/account/announcements',
        name: 'account-announcement',
        meta: {
            layout: 'account',
            title: 'Announecments - Account',
        },
        component: AccountAnnounecments
    },
    {
        path: '/account/announcements/create',
        name: 'account-announcement-create',
        meta: {
            layout: 'account',
            title: 'Create Announecment - Account',
        },
        component: CreateAnnounecment
    },
    {
        path: '/account/announcements/:id/edit',
        name: 'account-announcement-edit',
        meta: {
            layout: 'account',
            title: 'Edit Announecment - Account',
        },
        component: EditAnnounecment
    },
];

export default routes;