<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/announcements',
                    name: 'Announcements',
                },
                {
                    route: '/account/announcements',
                    name: 'Edit Announcements',
                },
            ]"
        />
        <announcement-form :isEdit="true" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import AnnouncementForm from "./components/AnnouncementForm.vue";

    export default {
        components: {
            AnnouncementForm,
        },

        mounted() {
            this.getAnnouncementById(this.$route.params.id);
        },

        methods: {
            ...mapActions("announcement", ["getAnnouncementById"]),
        },
    };
</script>