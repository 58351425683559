<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/certificates',
                name: 'Certificates',
            },
        ]" />
        <div v-if="selectedEmployer || false">
            <div class="row align-items-center">
                <div class="col-md-12 text-right">
                    <router-link :to="{ name: 'account-certificate-create' }"
                        class="btn btn-common btn-effect wow fadeInRight" data-wow-delay="0.9s">Create</router-link>
                </div>
            </div>
            <div v-if="arrangeCertificates.length || false" class="row mx-3 mb-5 mt-3">
                <certificate-card v-for="certificate in arrangeCertificates" :key="certificate.id"
                    :certificate="certificate" class="col-md-4" />
            </div>
            <div v-else class="row justify-content-center mb-5 mt-3">
                <empty :title="arrangeCertificatesText" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";
import CertificateCard from "@/components/employers/CertificateCard.vue";

export default {
    components: {
        Empty,
        CertificateCard,
    },

    computed: {
        ...mapState("employer", ["selectedEmployer"]),
        ...mapState("certificate", ["arrangeCertificates", "arrangeCertificatesText"]),
    },

    mounted() {
        if (this.selectedEmployer || false) {
            this.initAccountData(this.selectedEmployer.id);
        }
    },

    methods: {
        ...mapActions("certificate", [
            "initAccountData",
        ]),

    },
};
</script>