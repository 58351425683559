import Api from "./api.js";

const END_POINT = '/announcements';
const ACCOUNT_END_POINT = '/account' + END_POINT;

export default {

    all() {
        return Api.get(END_POINT);
    },

    accounts() {
        return Api.get(ACCOUNT_END_POINT);
    },

    store(data) {
        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        return Api.post(ACCOUNT_END_POINT, formData, {
            headers: {
                'Content-Type': 'multipart/form-data boundary=' + Math.random().toString(2),
            }
        });
    },

    find(id) {
        return Api.get(ACCOUNT_END_POINT + `/${id}`);
    },

    update(data) {
        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        formData.append('_method', 'PUT');
        return Api.post(ACCOUNT_END_POINT + `/${data.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data boundary=' + Math.random().toString(2),
            }
        });
    },

    destroy(id) {
        return Api.delete(ACCOUNT_END_POINT + `/${id}`);
    },

}