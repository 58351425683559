<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/certificates',
                    name: 'Certificates',
                },
                {
                    route: '/account/certificates',
                    name: 'Edit Certificate',
                },
            ]"
        />
        <certificate-form :isEdit="true" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import CertificateForm from "./components/CertificateForm.vue";

    export default {
        components: {
            CertificateForm,
        },

        mounted() {
            this.getCertificateById(this.$route.params.id);
        },

        methods: {
            ...mapActions("certificate", ["getCertificateById"]),
        },
    };
</script>