import Home from '../views/home/Index.vue';
import Employers from '../views/employers/Index.vue';
import ShowEmployer from '../views/employers/Show.vue';
import Opportunities from '../views/opportunities/Index.vue';
import ShowOpportunity from '../views/opportunities/Show.vue';
import Contact from '../views/contact/Index.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Home',
        },
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            title: 'Contact',
        },
        component: Contact
    },
    {
        path: '/employers',
        name: 'employers',
        meta: {
            title: 'Employers',
        },
        component: Employers
    },
    {
        path: '/employers/:id',
        name: 'employer-show',
        meta: {
            title: 'Employer Details',
        },
        component: ShowEmployer
    },
    {
        path: '/opportunities',
        name: 'opportunities',
        meta: {
            title: 'Opportunities',
        },
        component: Opportunities
    },
    {
        path: '/opportunities/:id',
        name: 'opportunity-show',
        meta: {
            title: 'Opportunities Details',
        },
        component: ShowOpportunity
    },
];

export default routes;