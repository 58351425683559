import Pricing from '../views/pricing/Index.vue';

const routes = [
    {
        path: '/pricing',
        name: 'pricing',
        meta: {
            title: 'Pricing',
        },
        component: Pricing
    },
];

export default routes;