import AccountAwards from './../../views/account/award/Index.vue';
import CreateAward from './../../views/account/award/Create.vue';
import EditAward from './../../views/account/award/Edit.vue';

const routes = [
    {
        path: '/account/awards',
        name: 'account-award',
        meta: {
            layout: 'account',
            title: 'Awards - Account',
        },
        component: AccountAwards
    },
    {
        path: '/account/awards/create',
        name: 'account-award-create',
        meta: {
            layout: 'account',
            title: 'Create Award - Account',
        },
        component: CreateAward
    },
    {
        path: '/account/awards/:id/edit',
        name: 'account-award-edit',
        meta: {
            layout: 'account',
            title: 'Edit Award - Account',
        },
        component: EditAward
    },
];

export default routes;