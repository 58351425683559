import Opportunity from "../../../services/apis/opportunity";

const state = () => ({
    employerId: null,
    opportunityStatistics: {},
    previewfile: null,
    selectedEmployerId: null,
    accountOpportunities: [],
    arrangeOpportunities: [],
    arrangeOpportunitiesText: 'Please Select Employer Profile',
    recentOpportunities: [],
    opportunities: [],
    opportunity: {
        id: '',
        user_id: '',
        employer_id: '',
        category_id: '',
        title: '',
        role: '',
        alias: '',
        gender: 'No Preference',
        age: 16,
        age_end: 64,
        handle_age_slider: [16, 64],
        address: '',
        atoll: '',
        island: '',
        publish_date: '',
        due_date: '',
        global: false,
        template: false,
        published: false,
        status: '',
        description: {
            opportunity_id: '',
            description: '',
        },
        negotiation: {
            opportunity_id: '',
            experience: '',
            qualification: '',
            certification: '',
            salary: '',
            salary_min: '',
            salary_max: '',
            currency: '',
            pay_period: 'Per Month',
            benefit_offers: '',
            negotiable: '',
        },
        info: {
            opportunity_id: '',
            employment_type: 'Full Time',
            career_level: '',
            employment_sector: '',
            industry: '',
            nationality: 'Local',
            work_place: 'Work Onsite',
            work_shift: 'Any',
        },
        additional: {
            opportunity_id: '',
            languages: '',
            skills: '',
            volunteer_experience: false,
            educational_certificates: false,
            employment_certificates: false,
            reference_letter: false,
            identity_card: false,
            passport: false,
            police_report: false,
            photograph: false,
            cv: false,
            apply_option: 'internal',
            apply_option_email: '',
            apply_option_url: '',
            people_with_disabilities: false,
            moms_working_from_home: false,
        },
        jobboards: [],
    },
});

const actions = {
    initAccountData: ({ dispatch, commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        dispatch("getAccountOpportunities");
    },

    getOpportunities: ({ commit }, page) => {
        Opportunity.all(page).then((response) => {
            commit("SET_OPPORTUNITIES", response.data);
        });
    },

    getRecentOpportunities: ({ commit }) => {
        Opportunity.recent().then((response) => {
            commit("SET_RECENT_OPPORTUNITIES", response.data);
        });
    },

    searchOpportunities: ({ commit }, data) => {
        Opportunity.search(data).then(response => {
            console.log(response);
            commit("SET_OPPORTUNITIES", response.data);
        });
    },

    createOpportunity: ({ commit }, opportunity) => {
        Opportunity.store(opportunity).then(() => {
            commit("CLEAR_OPPORTUNITY");
        });
    },

    getAccountOpportunities: ({ commit, state }) => {
        Opportunity.accounts().then((response) => {
            commit("SET_ACCOUNT_OPPORTUNITIES", response.data);
            if (state.employerId != null) {
                commit('GET_OPPORTUNITIES_BY_EMPLOYER_ID', state.employerId);
            }
        });
    },

    getOpportunitiesByEmployerId: ({ commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        commit('GET_OPPORTUNITIES_BY_EMPLOYER_ID', empId);
    },

    getOpportunityById: ({ commit }, id) => {
        Opportunity.find(id).then((response) => {
            commit("SET_OPPORTUNITY", response.data);
        });
    },

    getAccountOpportunityById: async ({ commit }, id) => {
        return Opportunity.findOnAccount(id).then((response) => {
            commit("SET_OPPORTUNITY", response.data);
            return response.data;
        });
    },

    setupOpportunityTemplate: async ({ commit }, id) => {
        return Opportunity.findOnAccount(id).then((response) => {
            commit("SET_OPPORTUNITY_TEMPLATE", response.data);
            return response.data;
        });
    },

    updateOpportunity: async ({ commit }, opportunity) => {
        return Opportunity.update(opportunity).then(() => {
            return commit("CLEAR_OPPORTUNITY");
        });
    },

    destroyOpportunity: (_, id) => {
        const txt = "Are you sure, you want to delete this opportunity profile?";
        if (confirm(txt)) {
            Opportunity.destroy(id);
        }
    },

    onFileChanged: ({ commit }, event) => {
        commit("SET_SELECTED_FILE", event);
    },

    updateOpportunityInput({ commit }, event) {
        commit('UPDATE_OPPORTUNITY_INPUT', event);
    },

    updateOpportunityInputByValue({ commit }, { key, value }) {
        commit('UPDATE_OPPORTUNITY_INPUT_BY_VLAUE', { key, value });
    },

    updateOpportunityDescriptionInput: ({ commit }, value) => {
        commit('UPDATE_OPPORTUNITY_DESCRIPTION_INPUT', value);
    },

    updateOpportunityMultipleSelect: ({ commit }, { key, key2, value }) => {
        commit('UPDATE_OPPORTUNITY_MULTIPLE_SELECT', { key, key2, value });
    },

    updateAgeOpportunityInput({ commit }, event) {
        commit('UPDATE_AGE_OPPORTUNITY_INPUT', event);
    },

    setSelectedEmployerIdOnOpportunity: ({ commit }) => {
        commit("SET_SELECTED_EMPLOYER_ID_ON_OPPORTUNITY");
    },

    clearOldIslandValue: ({ commit }) => {
        commit('CLEAR_ISLAND_INPUT');
    },

    setSelectedJobboards: ({ commit }, data) => {
        commit("SET_JOBBOARDS", data);
    },

    getOpportunityStatistics: ({ commit }) => {
        Opportunity.getStatistics().then((response) => {
            commit("SET_OPPORTUNITY_STATISTICS", response.data);
        });
    }
};

const getters = {};

const mutations = {
    SET_OPPORTUNITY: (state, opportunity) => {
        state.opportunity = opportunity;
        state.opportunity['handle_age_slider'] = [opportunity['age'] || 16, opportunity['age_end'] || 64];
    },

    SET_OPPORTUNITY_TEMPLATE: (state, opportunity) => {
        state.opportunity = opportunity;
        state.opportunity['id'] = '';
        state.opportunity['employer_id'] = state.selectedEmployerId;
        state.opportunity['template'] = false;
        state.opportunity['global'] = false;
        state.opportunity['published'] = false;
        state.opportunity['status'] = '';
        state.opportunity['publish_date'] = '';
        state.opportunity['due_date'] = '';
        if(state.opportunity['age'] || false) {
            state.opportunity['age'] = 16;
        }
        if(state.opportunity['age_end'] || false) {
            state.opportunity['age_end'] = 64;
        }
        state.opportunity['handle_age_slider'] = [state.opportunity['age'] || 16, state.opportunity['age_end'] || 64];
    },

    SET_OPPORTUNITIES: (state, opportunities) => {
        state.opportunities = opportunities;
    },

    SET_ACCOUNT_OPPORTUNITIES: (state, opportunities) => {
        state.accountOpportunities = opportunities;
    },

    SET_RECENT_OPPORTUNITIES: (state, opportunities) => {
        state.recentOpportunities = opportunities;
    },

    UPDATE_OPPORTUNITY_INPUT: (state, event) => {
        const temp = event.target.name.split('.');
        if (temp.length > 1) {
            if (event.target.type == 'checkbox') {
                state.opportunity[temp[0]][temp[1]] = event.target.checked;
            } else {
                state.opportunity[temp[0]][temp[1]] = event.target.value;
            }
        } else {
            if (event.target.type == 'checkbox') {
                state.opportunity[event.target.name] = event.target.checked;
            } else {
                state.opportunity[event.target.name] = event.target.value;
            }
        }
    },

    UPDATE_OPPORTUNITY_INPUT_BY_VLAUE: (state, { key, value }) => {
        state.opportunity[key] = value;
    },

    UPDATE_OPPORTUNITY_DESCRIPTION_INPUT: (state, value) => {
        state.opportunity.description.description = value;
    },

    UPDATE_OPPORTUNITY_MULTIPLE_SELECT: (state, { key, key2, value }) => {
        if (key2 != null) {
            state.opportunity[key][key2] = value;
        } else {
            state.opportunity[key] = value;
        }
    },

    UPDATE_AGE_OPPORTUNITY_INPUT: (state, value) => {
        console.log(value);
        state.opportunity['handle_age_slider'] = value;
        state.opportunity['age'] = state.opportunity['handle_age_slider'][0] || 16;
        state.opportunity['age_end'] = state.opportunity['handle_age_slider'][1] || 64;
    },

    SET_SELECTED_EMPLOYER_ID_ON_OPPORTUNITY: (state) => {
        state.opportunity.employer_id = state.selectedEmployerId;
    },

    CLEAR_OPPORTUNITY: (state) => {
        state.opportunity = {
            id: '',
            user_id: '',
            employer_id: '',
            categories: '',
            name: '',
            title: '',
            role: '',
            alias: '',
            gender: '',
            address: '',
            atoll: '',
            island: '',
            publish_date: '',
            due_date: '',
            template: '',
            verified: '',
            status: '',
            description: {
                opportunity_id: '',
                description: '',
            },
            negotiation: {
                opportunity_id: '',
                experience: '',
                qualification: '',
                certification: '',
                salary: '',
                salary_min: '',
                salary_max: '',
                currency: '',
                pay_period: '',
                benefit_offers: '',
                negotiable: '',
            },
            info: {
                opportunity_id: '',
                employment_type: '',
                career_level: '',
                employment_sector: '',
                industry: '',
                nationality: '',
                work_place: '',
                work_shift: '',
            },
            additional: {
                opportunity_id: '',
                languages: '',
                skills: '',
                volunteer_experience: false,
                educational_certificates: false,
                employment_certificates: false,
                reference_letter: false,
                identity_card: false,
                passport: false,
                police_report: false,
                photograph: false,
                cv: false,
                apply_option: '',
                apply_option_email: '',
                apply_option_url: '',
                people_with_disabilities: false,
                moms_working_from_home: false,
            },
        };
        state.arrangeOpportunities = [];
    },

    CLEAR_ISLAND_INPUT: (state) => {
        state.opportunity.island = '';
    },

    GET_OPPORTUNITIES_BY_EMPLOYER_ID: (state, employerId) => {
        state.selectedEmployerId = employerId;
        state.arrangeOpportunities = [];
        state.arrangeOpportunities = state.accountOpportunities.filter(item => item.employer_id == employerId);
        state.arrangeOpportunitiesText = state.arrangeOpportunities.length ? '' : '0 Opportunity Found!';
    },

    SET_JOBBOARDS: (state, data) => {
        state.opportunity.jobboards = [...data];
    },

    SET_OPPORTUNITY_STATISTICS: (state, data) => {
        state.opportunityStatistics = data;
    },

    SET_EMPLOYER_ID: (state, id) => {
        state.employerId = id;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};

