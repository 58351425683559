import AccountPhotos from './../../views/account/photo/Index.vue';
import CreatePhoto from './../../views/account/photo/Create.vue';
import EditPhoto from './../../views/account/photo/Edit.vue';

const routes = [
    {
        path: '/account/photos',
        name: 'account-photo',
        meta: {
            layout: 'account',
            title: 'Photos - Account',
        },
        component: AccountPhotos
    },
    {
        path: '/account/photos/create',
        name: 'account-photo-create',
        meta: {
            layout: 'account',
            title: 'Create Photo - Account',
        },
        component: CreatePhoto
    },
    {
        path: '/account/photos/:id/edit',
        name: 'account-photo-edit',
        meta: {
            layout: 'account',
            title: 'Edit Photo - Account',
        },
        component: EditPhoto
    },
];

export default routes;