<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/profile',
                    name: 'Profile',
                },
            ]"
        />
        <form @submit.prevent="action" v-if="currentUser.token || false">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Name"
                                        name="name"
                                        :value="currentUser.user.name || ''"
                                    />
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Email"
                                        name="email"
                                        :value="currentUser.user.email || ''"
                                    />
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Phone"
                                        name="phone"
                                        :value="currentUser.user.phone || ''"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <button type="submit" class="btn btn-common log-btn">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {

        computed: {
            ...mapState("auth", ["currentUser"]),
        },

        methods: {
            action() {
                //
            },
        }
    };
</script>
