<template>
    <div>
        <div class="container">
            <opportunity-info :opportunity="opportunity" />
        </div>

        <section class="pb-5">
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 column">
                            <div class="job-wide-devider">
                                <div class="row">
                                    <div class="col-lg-8 column">
                                        <div class="job-details">
                                            <div v-if="opportunity.description.description || ''"
                                                v-html="opportunity.description.description"></div>
                                        </div>
                                        <div class="job-overview">
                                            <ul class="d-flex mt-3">
                                                <li>
                                                    <h3>Gender</h3>
                                                    <span>{{ opportunity.gender || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Age</h3>
                                                    <span>
                                                        {{ opportunity.age || '' }}
                                                        {{ (opportunity.age_end || false) ? ' - ' : '' }}
                                                        {{ opportunity.age_end || '' }}
                                                    </span>
                                                </li>
                                                <li>
                                                    <h3>Nationality</h3>
                                                    <span>{{ opportunity.info?.nationality || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Languages</h3>
                                                    <span>{{ opportunity.additional?.languages || "" }}</span>
                                                </li>
                                            </ul>
                                            <ul class="d-flex mt-3">
                                                <li>
                                                    <h3>Employment Type</h3>
                                                    <span>{{ opportunity.info?.employment_type || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Career Level</h3>
                                                    <span>{{ opportunity.info?.career_level || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Workplace</h3>
                                                    <span>{{ opportunity.info?.work_place || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Workshift</h3>
                                                    <span>{{ opportunity.info?.work_shift || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Industry</h3>
                                                    <span>{{ opportunity.info?.industry || "" }}</span>
                                                </li>
                                            </ul>
                                            <ul class="d-flex mt-3">
                                                <li>
                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.educational_certificates ?? false" />
                                                                <label class="form-check-label" for="">Educational
                                                                    Certificates</label>
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.employment_certificates ?? false" />
                                                                <label class="form-check-label" for="">Employment
                                                                    Certificates</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.reference_letter ?? false" />
                                                                <label class="form-check-label" for="">Reference
                                                                    Letter</label>
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.identity_card ?? false" />
                                                                <label class="form-check-label" for="">Identity Card</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.passport ?? false" />
                                                                <label class="form-check-label" for="">Passport</label>
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.police_report ?? false" />
                                                                <label class="form-check-label" for="">Police Report</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.photograph ?? false" />
                                                                <label class="form-check-label" for="">Photograph</label>
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="checkbox" class="form-check-input" id=""
                                                                    disabled
                                                                    :checked="opportunity.additional?.cv ?? false" />
                                                                <label class="form-check-label" for="">CV</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul class="d-flex mt-3">
                                                <li>
                                                    <h3>Pay Period</h3>
                                                    <span>{{ opportunity.negotiation?.pay_period || "" }}</span>
                                                </li>
                                                <li>
                                                    <h3>Salary</h3>
                                                    <span v-if="(opportunity.negotiation?.salary || '') == 'Show'">
                                                        ({{ opportunity.negotiation?.salary_min || "" }}
                                                        -
                                                        {{ opportunity.negotiation?.salary_max || "" }})
                                                        {{ opportunity.negotiation?.currency || "" }}
                                                    </span>
                                                    <span v-else>
                                                        {{ opportunity.negotiation?.salary || "" }}
                                                    </span>
                                                </li>
                                                <li>
                                                    <h3>Benefits</h3>
                                                    <span>{{ opportunity.negotiation?.benefit_offers || "" }}</span>
                                                </li>
                                                <li>
                                                    <div class="form-group">
                                                        <input type="checkbox" class="form-check-input" id="" disabled
                                                            :checked="opportunity.negotiation?.negotiable ?? false" />
                                                        <label class="form-check-label" for="">Negotiable</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 column">
                                        <ul class="d-flex mt-3">
                                            <li>
                                                <h3>Experience</h3>
                                                <span>{{ opportunity.negotiation?.experience || "" }}</span>
                                            </li>
                                            <li>
                                                <h3>Qualification</h3>
                                                <span>{{ opportunity.negotiation?.qualification || "" }}</span>
                                            </li>
                                            <li>
                                                <h3>Certification</h3>
                                                <span>{{ opportunity.negotiation?.certification || "" }}</span>
                                            </li>
                                            <li>
                                                <h3>Skills</h3>
                                                <span>{{ opportunity.additional?.skills || "" }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OpportunityInfo from "./components/OpportunityInfo.vue";
// import EmployerOverview from "./components/EmployerOverview.vue";
// import EmployerActivity from "./components/EmployerActivity.vue";

export default {
    components: { OpportunityInfo, },

    computed: {
        ...mapState("opportunity", ["opportunity"]),
    },

    mounted() {
        this.getOpportunityById(this.$route.params.id);
    },

    methods: {
        ...mapActions("opportunity", ["getOpportunityById"]),
    },
};
</script>