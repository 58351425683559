import AccountEmployers from './../../views/account/employer/Index.vue';
import CreateEmployer from './../../views/account/employer/Create.vue';
import EditEmployer from './../../views/account/employer/Edit.vue';

const routes = [
    {
        path: '/account/employer',
        name: 'account-employer',
        meta: {
            layout: 'account',
            title: 'Employers - Account',
        },
        component: AccountEmployers
    },
    {
        path: '/account/employer/create',
        name: 'account-employer-create',
        meta: {
            layout: 'account',
            title: 'Create Employer - Account',
        },
        component: CreateEmployer
    },
    {
        path: '/account/employer/:id/edit',
        name: 'account-employer-edit',
        meta: {
            layout: 'account',
            title: 'Edit Employer - Account',
        },
        component: EditEmployer
    },
];

export default routes;