<template>
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="form-group">
                <label>Employment Type</label>
                <select class="form-control" name="info.employment_type" :value="opportunity.info?.employment_type ?? ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.employment_type" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Career Level</label>
                <select class="form-control" name="info.career_level" :value="opportunity.info?.career_level ?? ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.career_level" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Workplace</label>
                <select class="form-control" name="info.work_place" :value="opportunity.info?.work_place ?? ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.workplace" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Workshift</label>
                <select class="form-control" name="info.work_shift" :value="opportunity.info?.work_shift ?? ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.workshift" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Category/Field Of Work</label>
                <select class="form-control" name="category_id" :value="opportunity.category_id"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.category" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Industry</label>
                <select class="form-control" name="info.industry" :value="opportunity.info?.industry ?? ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.industry" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Due Date</label>
                <input type="datetime-local" class="form-control" placeholder="Due Date" name="due_date"
                    :value="opportunity.due_date" @input="handleOpportunityInput" />
            </div>
        </div>
    </div>
</template>


<script>
import OpportunityMixin from '@/mixins/OpportunityMixin.js';

export default {
    props: {
        isEdit: Boolean,
    },

    mixins: [OpportunityMixin],

};
</script>