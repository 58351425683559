import OptionProperty from "../../../services/apis/optionProperty";

const state = () => ({
    options: {},
});

const actions = {
    getOptionProperty: ({ commit }) => {
        OptionProperty.all().then(response => {
            commit('SET_OPTION_PROPERTY', response.data);
        });
    },

    getIslandsByAtollName: ({ commit }, name) => {
        OptionProperty.getIslandsByAtollName(name).then(response => {
            commit('SET_ISLAND', response.data);
        });
    },
};

const mutations = {
    SET_OPTION_PROPERTY: (state, data) => {
        state.options = data;
    },

    SET_ISLAND: (state, data) => {
        state.options.island = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
