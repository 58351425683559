import { createStore } from 'vuex';
import auth from './modules/auth/index';
import contact from './modules/contact/index';
import optionProperty from './modules/optionProperty/index';
import employer from './modules/employer/index';
import teamRole from './modules/employer/team/index';
import announcement from './modules/employer/announcement/index';
import certificate from './modules/employer/certificate/index';
import award from './modules/employer/award/index';
import photo from './modules/employer/photo/index';
import opportunity from './modules/opportunity/index';
import appearance from './modules/appearance/index';
import packages from './modules/packages/index';
import jobboard from './modules/jobboard/index';

export default createStore({
    modules: {
        auth,
        contact,
        optionProperty,
        employer,
        teamRole,
        announcement,
        certificate,
        award,
        photo,
        opportunity,
        appearance,
        packages,
        jobboard,
    }
});
