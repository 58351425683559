<template>
    <div class="column col-lg-4 col-md-6 col-xs-12">
        <div class="job-featured">
            <div class="row">
                <div v-if="opportunity.employer_logo" class="job-logo w-100">
                    <img :src="opportunity.employer_logo" alt="" class="max-w-100px" />
                </div>
                <div class="content mb-5"></div>
            </div>

            <div class="row mt-4 ml-2">
                <div class="job-content">
                    <h3 class="mt-2">
                        <a href="#">{{ opportunity.title }}</a>
                    </h3>
                    <span>{{ opportunity.employer_name }}</span>
                    <div class="mt-3">
                        <span class="mr-5"><i class="fas fa-archive"></i> {{ opportunity.employment_type }}</span>
                        <span class="ml-3">
                            <i class="fas fa-money-bill-alt"></i>
                            {{ opportunity.salary + " " + opportunity.currency }}
                        </span>
                    </div>
                    <div class="mt-2">
                        <span class="mr-2"><i class="far fa-clock"></i> Posted {{ opportunity.publish_date }}</span>
                        <span class=""><i class="far fa-clock"></i> {{ opportunity.due_date_human || "" }}</span>
                    </div>
                </div>
            </div>
            <div class="row job-btn mt-4">
                <div class="col-md-6 mt-1 job-opp-footer">
                    <span><i class="fas fa-map-marker-alt"></i>{{ opportunity.address || "" }}</span>
                </div>
                <div class="col-md-6 job-opp-footer">
                    <router-link :to="{
                        name: 'opportunity-show',
                        params: { id: opportunity.id },
                    }" class="btn">Browse Job</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.listview-wrapper .job-details {
    margin: 0 !important;
}
</style>

<script>
export default {
    props: ["opportunity"],
};
</script>