import TeamRole from "../../../../services/apis/team-role";

const state = () => ({
    employerId: null,
    teamRoles: [],
    arrangeTeamRoles: [],
    arrangeTeamRolesText: 'Please Select Employer Profile',
    teamRole: {
        id: '',
        user_id: '',
        employer_id: '',
        title: '',
    },
    teamModuleAccessData: [
        {
            name: 'Opportunity',
            module: 'opportunity',
            create: true,
            update: true,
            delete: false,
        },
        {
            name: 'Award',
            module: 'award',
            create: true,
            update: true,
            delete: false,
        },
        {
            name: 'Announcement',
            module: 'announcement',
            create: true,
            update: true,
            delete: false,
        },
        {
            name: 'Photo',
            module: 'photo',
            create: true,
            update: true,
            delete: false,
        },
        {
            name: 'Certificate',
            module: 'certificate',
            create: true,
            update: true,
            delete: false,
        },
    ],
});

const actions = {
    initAccountData: ({ dispatch, commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        dispatch("getAccountTeamRoles");
    },

    createTeamRole: async ({ commit, state }, teamRole) => {
        return TeamRole.store({
            roleAccess: state.teamModuleAccessData,
            ...teamRole,
        }).then(() => {
            commit("CLEAR_TEAM_ROLE");
        });
    },

    getAccountTeamRoles: async ({ commit, state }) => {
        return TeamRole.accounts().then((response) => {
            commit("SET_TEAM_ROLES", response.data);
            if (state.employerId != null) {
                commit('GET_TEAM_ROLES_BY_EMPLOYER_ID', state.employerId);
            }
        });
    },

    getTeamRolesByEmployerId: ({ commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        commit('GET_TEAM_ROLES_BY_EMPLOYER_ID', empId);
    },

    getTeamRoleById: ({ commit }, id) => {
        TeamRole.find(id).then((response) => {
            commit("SET_TEAM_ROLE", response.data);
        });
    },

    updateTeamRole: async ({ commit, state }, teamRole) => {
        return TeamRole.update({
            roleAccess: state.teamModuleAccessData,
            ...teamRole,
        }).then(() => {
            commit("CLEAR_TEAM_ROLE");
        });
    },

    destroyTeamRole: (_, id) => {
        const txt = "Are you sure, you want to delete this Role?";
        if (confirm(txt)) {
            TeamRole.destroy(id);
        }
    },

    updateTeamRoleInput({ commit }, event) {
        commit('UPDATE_TEAM_ROLE_INPUT', event);
    },

    arrangeTeamRoleByEmployer({ commit }, teamRoles) {
        commit('ARRANGE_BY_EMPLOYER_PROFILE', teamRoles);
    },

    clearTeamRoleForm: ({ commit }, empId) => {
        commit("CLEAR_TEAM_ROLE", empId);
    }
};

const getters = {};

const mutations = {
    SET_TEAM_ROLE: (state, teamRole) => {
        state.teamRole = teamRole;
        (teamRole.access || []).forEach(item => {
            const stateAccess = state.teamModuleAccessData.filter(
                moduleAccess => moduleAccess.module == item.module
            );
            if (stateAccess.length > 0) {
                stateAccess[0].create = item.create == 1;
                stateAccess[0].update = item.update == 1;
                stateAccess[0].delete = item.delete == 1;
            }
        });
    },

    SET_TEAM_ROLES: (state, teamRoles) => {
        state.teamRoles = teamRoles;
    },

    CLEAR_TEAM_ROLE: (state, empId) => {
        state.teamRole = {
            id: '',
            user_id: '',
            employer_id: empId,
            title: '',
        };
        state.arrangeTeamRoles = [];
    },

    UPDATE_TEAM_ROLE_INPUT: (state, event) => {
        let temp = state.teamRole;
        temp[event.target.name] = event.target.value;
        state.teamRole = temp;
    },

    GET_TEAM_ROLES_BY_EMPLOYER_ID: (state, employerId) => {
        state.arrangeTeamRoles = [];
        state.arrangeTeamRoles = state.teamRoles.filter(item => item.employer_id == employerId);
        state.arrangeTeamRolesText = state.arrangeTeamRoles.length ? '' : '0 Team Role Found!';
    },

    SET_EMPLOYER_ID: (state, id) => {
        state.employerId = id;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
