<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/team-roles',
                    name: 'Team Roles',
                },
                {
                    route: '/account/team-roles',
                    name: 'Create Team Role',
                },
            ]"
        />
        <team-role-form :isEdit="false" />
    </div>
</template>

<script>
    import TeamRoleForm from "./components/TeamRoleForm.vue";

    export default {
        components: {
            TeamRoleForm,
        },
    };
</script>