<template>
    <div class="row mx-1">
        <div v-if="accounts.length ?? false" class="col-md-12 mt-3 card box">
            <h6>{{ isEdit || false ? "Edit" : "Create" }} Team Role</h6>
            <form @submit.prevent="action">
                <div class="form-row">
                    <div v-if="!(isEdit || false)" class="form-group col-md-6">
                        <label>Select Employer Profile</label>
                        <select class="form-control" name="employer_id" :value="teamRole.employer_id"
                            @input="updateTeamRoleInput">
                            <option v-for="option in accounts" :key="option.id" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Title</label>
                        <input type="text" class="form-control" placeholder="Type Certificate Name" name="title"
                            :value="teamRole.title" @input="updateTeamRoleInput" required autofocus />
                    </div>
                </div>
                <div class="form-row">
                    <div v-for="moduleAccess in teamModuleAccessData" :key="moduleAccess.module" class="form-group col-md-12">
                        <label>{{ moduleAccess.name || '' }}</label>
                        <div class="row">
                            <div class="col-md-3 mx-4">
                                <div class="form-group">
                                    <input type="checkbox" class="form-check-input"
                                        :id="`${(moduleAccess.module || '')}_create`" v-model="moduleAccess.create" />
                                    <label class="form-check-label"
                                        :for="`${(moduleAccess.module || '')}_create`">Create</label>
                                </div>
                            </div>
                            <div class="col-md-3 mx-4">
                                <div class="form-group">
                                    <input type="checkbox" class="form-check-input"
                                        :id="`${(moduleAccess.module || '')}_update`" v-model="moduleAccess.update" />
                                    <label class="form-check-label"
                                        :for="`${(moduleAccess.module || '')}_update`">Update</label>
                                </div>
                            </div>
                            <div class="col-md-3 mx-4">
                                <div class="form-group">
                                    <input type="checkbox" class="form-check-input"
                                        :id="`${(moduleAccess.module || '')}_delete`" v-model="moduleAccess.delete" />
                                    <label class="form-check-label"
                                        :for="`${(moduleAccess.module || '')}_delete`">Delete</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-common log-btn">
                    {{ isEdit || false ? "Update" : "Save" }}
                </button>
            </form>
        </div>
        <div v-else class="col-md-12 mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";

export default {
    props: {
        isEdit: Boolean,
    },

    components: { Empty },

    computed: {
        ...mapState("teamRole", ["teamRole", "teamModuleAccessData"]),
        ...mapState("employer", ["accounts", "selectedEmployer"]),
    },

    mounted() {
        this.clearTeamRoleForm(this.selectedEmployer?.id || '');
        if (this.accounts.length == 0) {
            this.getAccountEmployers();
        }
    },

    methods: {
        ...mapActions("teamRole", [
            "createTeamRole",
            "updateTeamRole",
            "updateTeamRoleInput",
            "clearTeamRoleForm",
        ]),
        ...mapActions("employer", ["getAccountEmployers"]),

        action() {
            this.isEdit ? this.update() : this.create();
        },

        create() {
            this.createTeamRole(this.teamRole)
                .then(() => {
                    this.$router.push({
                        name: "account-team-roles",
                    });
                    this.successToast();
                })
                .catch(() => {
                    this.errorToast();
                });
        },

        update() {
            this.updateTeamRole(this.teamRole)
                .then(() => {
                    this.successToast();
                    this.$router.push({
                        name: "account-team-roles",
                    });
                })
                .catch(() => {
                    this.errorToast();
                });
        },
    },
};
</script>