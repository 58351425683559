import Certificate from "../../../../services/apis/certificate";

const state = () => ({
    employerId: null,
    certificates: [],
    arrangeCertificates: [],
    arrangeCertificatesText: 'Please Select Employer Profile',
    certificate: {
        id: '',
        user_id: '',
        employer_id: '',
        name: '',
        organization: '',
        issue_date: '',
        credential_id: '',
        credential_url: '',
        status: '',
    },
});

const actions = {
    initAccountData: ({ dispatch, commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        dispatch("getAccountCertificates");
    },

    createCertificate: async ({ commit }, certificate) => {
        return Certificate.store(certificate).then(() => {
            commit("CLEAR_CERTIFICATE");
        });
    },

    getAccountCertificates: async ({ commit, state }) => {
        return Certificate.accounts().then((response) => {
            commit("SET_CERTIFICATES", response.data);
            if (state.employerId != null) {
                commit('GET_CERTIFICATES_BY_EMPLOYER_ID', state.employerId);
            }
        });
    },

    getCertificatesByEmployerId: ({ commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        commit('GET_CERTIFICATES_BY_EMPLOYER_ID', empId);
    },

    getCertificateById: ({ commit }, id) => {
        Certificate.find(id).then((response) => {
            commit("SET_CERTIFICATE", response.data);
        });
    },

    updateCertificate: async ({ commit }, certificate) => {
        return Certificate.update(certificate).then(() => {
            commit("CLEAR_CERTIFICATE");
        });
    },

    destroyCertificate: (_, id) => {
        const txt = "Are you sure, you want to delete this certificate?";
        if (confirm(txt)) {
            Certificate.destroy(id);
        }
    },

    updateCertificateInput({ commit }, event) {
        commit('UPDATE_CERTIFICATE_INPUT', event);
    },

    arrangeCertificateByEmployer({ commit }, certificates) {
        commit('ARRANGE_BY_EMPLOYER_PROFILE', certificates);
    },

    clearCertificateForm: ({ commit }, empId) => {
        commit("CLEAR_CERTIFICATE", empId);
    }
};

const getters = {};

const mutations = {
    SET_CERTIFICATE: (state, certificate) => {
        state.certificate = certificate;
    },

    SET_CERTIFICATES: (state, certificates) => {
        state.certificates = certificates;
    },

    CLEAR_CERTIFICATE: (state, empId) => {
        state.certificate = {
            id: '',
            user_id: '',
            employer_id: empId || '',
            name: '',
            organization: '',
            issue_date: '',
            credential_id: '',
            credential_url: '',
            status: '',
        };
        state.arrangeCertificates = [];
    },

    UPDATE_CERTIFICATE_INPUT: (state, event) => {
        let temp = state.certificate;
        temp[event.target.name] = event.target.value;
        state.certificate = temp;
    },

    GET_CERTIFICATES_BY_EMPLOYER_ID: (state, employerId) => {
        state.arrangeCertificates = [];
        state.arrangeCertificates = state.certificates.filter(item => item.employer_id == employerId);
        state.arrangeCertificatesText = state.arrangeCertificates.length ? '' : '0 Certificate Found!';
    },

    SET_EMPLOYER_ID: (state, id) => {
        state.employerId = id;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
