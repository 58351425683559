<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/opportunities',
                name: 'Opportunities',
            },
        ]" />
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Category/Field Of Work</label>
                    <select class="form-control" @input="onCategoryChange">
                        <option value="">Select</option>
                        <option v-for="option in options.category" :key="option.id" :value="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="accountOpportunities.length || false" class="row mx-3 mb-5 mt-3">
            <div class="col-12">
                <opportunity-list-view-card v-for="opportunity in opportunities" :key="opportunity.id"
                    :opportunity="opportunity" :isAccount="currentUser.user.role == 'Admin'" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="No Templates Found" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";
import OpportunityListViewCard from "@/components/opportunities/OpportunityListViewTemplateCard.vue";

export default {
    components: {
        Empty,
        OpportunityListViewCard,
    },

    data: () => ({
        opportunities: [],
    }),

    computed: {
        ...mapState("auth", ["currentUser"]),
        ...mapState("employer", ["accounts"]),
        ...mapState("opportunity", ["accountOpportunities", "selectedEmployerId"]),
        ...mapState("optionProperty", ["options"]),
    },

    mounted() {
        if (!(this.selectedEmployerId || false)) {
            this.$router.push({
                name: "account-opportunity",
            });
        }
        this.getAccountEmployers();
        this.getAccountOpportunities();
        this.opportunities = this.accountOpportunities.filter(
            (item) => (item.template || false) || (item.global || false)
        );

    },

    methods: {
        ...mapActions("employer", ["getAccountEmployers"]),
        ...mapActions("opportunity", [
            "getAccountOpportunities",
            "getOpportunitiesByEmployerId",
        ]),

        onCategoryChange(e) {
            if (e.target.value || false) {
                this.opportunities = this.accountOpportunities.filter(
                    (item) => ((item.template || false) || (item.global || false)) && item.category_id == e.target.value
                );
            } else {
                this.opportunities = this.accountOpportunities.filter(
                    (item) => (item.template || false) || (item.global || false)
                );
            }
        },
    },
};
</script>