<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/photos',
                    name: 'Photos',
                },
                {
                    route: '/account/photos',
                    name: 'Edit Photo',
                },
            ]"
        />
        <photo-form :isEdit="true" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import PhotoForm from "./components/PhotoForm.vue";

    export default {
        components: {
            PhotoForm,
        },

        mounted() {
            this.getPhotoById(this.$route.params.id);
        },

        methods: {
            ...mapActions("photo", ["getPhotoById"]),
        },
    };
</script>