// import $ from "jquery";
import { mapState, mapActions } from "vuex";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {

    data: () => ({
        laoding: false,
        editor: ClassicEditor,
        showJobboards: false,
        handleJobTitle: '',
        handleExperience: [],
        handleQualification: [],
        handleCertification: [],
        handleLanguages: [],
        handleSkills: [],
        handleBenefitOffer: [],
        applyOption: '',
        submitType: '',
        jobDueDate: '',
        jobPublishBtnText: 'Publish',
        formValidationSchema: {
            'general': [
                {
                    name: 'Title',
                    key: 'title',
                    items: [],
                },
                {
                    name: 'Role',
                    key: 'role',
                    items: [],
                },
                {
                    name: 'Alias',
                    key: 'alias',
                    items: '',
                },
                {
                    name: 'Description',
                    key: 'description',
                    items: [
                        {
                            name: 'Description',
                            key: 'description',
                        }
                    ],
                },
            ],
            'requirement': [
                {
                    name: 'Gender',
                    key: 'gender',
                    items: [],
                },
                {
                    name: 'Age',
                    key: 'age',
                    items: [],
                },
                {
                    name: 'Age',
                    key: 'age_end',
                    items: [],
                },
                {
                    name: 'Requirement',
                    key: 'negotiation',
                    items: [
                        {
                            name: 'Experience',
                            key: 'experience',
                        },
                        {
                            name: 'Qualification',
                            key: 'qualification',
                        },
                        {
                            name: 'Certification',
                            key: 'certification',
                        },
                    ],
                },
                {
                    name: 'Requirement',
                    key: 'info',
                    items: [
                        {
                            name: 'Nationality',
                            key: 'nationality'
                        }
                    ],
                },
                {
                    name: 'Requirement',
                    key: 'additional',
                    items: [
                        {
                            name: 'Languages',
                            key: 'languages'
                        },
                        {
                            name: 'Skills',
                            key: 'skills'
                        }
                    ],
                },
            ],
            'document': [],
            'negotiate': [
                {
                    name: 'Negotiate',
                    key: 'negotiation',
                    items: [
                        {
                            name: 'Currency',
                            key: 'currency'
                        },
                        {
                            name: 'Salary',
                            key: 'salary'
                        },
                        {
                            name: 'Pay Period',
                            key: 'pay_period'
                        }
                    ],
                },
            ],
            'info': [
                {
                    name: 'Field Of Work',
                    key: 'category_id',
                    items: [],
                },
                {
                    name: 'Info',
                    key: 'info',
                    items: [
                        {
                            name: 'employment_type',
                            key: 'employment_type'
                        },
                        {
                            name: 'career_level',
                            key: 'career_level'
                        },
                        {
                            name: 'industry',
                            key: 'industry'
                        },
                        {
                            name: 'work_place',
                            key: 'work_place'
                        },
                        {
                            name: 'work_shift',
                            key: 'work_shift'
                        },
                    ],
                },
            ],
            'other': [],
        },
    }),

    watch: {

        handleExperience(val) {
            this.updateOpportunityMultipleSelect({ key: 'negotiation', key2: 'experience', value: val || '' });
        },

        handleQualification(val) {
            this.updateOpportunityMultipleSelect({ key: 'negotiation', key2: 'qualification', value: val || '' });
        },

        handleCertification(val) {
            this.updateOpportunityMultipleSelect({ key: 'negotiation', key2: 'certification', value: val || '' });
        },

        handleLanguages(val) {
            this.updateOpportunityMultipleSelect({ key: 'additional', key2: 'languages', value: val || '' });
        },

        handleSkills(val) {
            this.updateOpportunityMultipleSelect({ key: 'additional', key2: 'skills', value: val || '' });
        },

        handleBenefitOffer(val) {
            this.updateOpportunityMultipleSelect({ key: 'negotiation', key2: 'benefit_offers', value: val || '' });
        },

        handleJobTitle(val) {
            this.updateOpportunityInputByValue({ key: 'title', value: val || '' });
        },

    },

    computed: {
        ...mapState("auth", ["currentUser"]),
        ...mapState("opportunity", ["opportunity", "selectedEmployerId"]),
        ...mapState("optionProperty", ["options"]),
        ...mapState("employer", ["accounts"]),
    },

    mounted() {
        this.init();
    },

    methods: {
        ...mapActions("optionProperty", ["getOptionProperty", "getIslandsByAtollName"]),
        ...mapActions("opportunity", [
            "createOpportunity",
            "updateOpportunity",
            "updateOpportunityInput",
            "updateOpportunityInputByValue",
            "updateOpportunityDescriptionInput",
            "updateOpportunityMultipleSelect",
            "clearOldIslandValue",
            "setSelectedEmployerIdOnOpportunity",
            "getAccountOpportunityById",
            "updateAgeOpportunityInput",
            "setupOpportunityTemplate",
        ]),
        ...mapActions("employer", ["getAccountEmployers"]),

        handleSkillsBtn(val) {
            this.handleSkills = [(val || ''), ...(this.opportunity.additional?.skills || [])];
        },

        async init() {
            this.setSelectedEmployerIdOnOpportunity();
            this.getOptionProperty();
            if (this.isEdit || false) {
                this.submitType = 'Update';
            }

            if (this.$route.params.template || false) {
                this.laoding = true;
                const data = await this.setupOpportunityTemplate(this.$route.params.id);
                await this.initData(data);
                this.laoding = false;
            } else if (this.$route.params.id || false) {
                this.laoding = true;
                const data = await this.getAccountOpportunityById(this.$route.params.id);
                await this.initData(data);
                this.laoding = false;
            }
        },

        async initData(data) {
            this.handleJobTitle = data.title;
            this.handleExperience = JSON.parse(data.negotiation?.experience || '[]');
            this.handleQualification = JSON.parse(data.negotiation?.qualification || '[]');
            this.handleCertification = JSON.parse(data.negotiation?.certification || '[]');
            this.handleLanguages = JSON.parse(data.additional?.languages || '[]');
            this.handleSkills = JSON.parse(data.additional?.skills || '[]');
            this.handleBenefitOffer = JSON.parse(data.negotiation?.benefit_offers || '[]');
            this.applyOption = data.additional?.apply_option || '';
            if (data.due_date || false) {
                this.jobDueDate = new Date(data.due_date);
            }
            await this.getAccountEmployers();
            this.checkForRePublish();
        },

        action() {
            if (this.currentUser.user.role != 'Admin') {
                this.updateOpportunityInputByValue({ key: 'global', value: false });
            }
            if (this.submitType == 'Update') {
                this.update();
            } else {
                this.create();
            }
        },

        create() {
            this.createOpportunity(this.opportunity)
                .then(() => {
                    this.$router.push({
                        name: "account-opportunity",
                    });
                    this.successToast();
                })
                .catch((data) => {
                    this.errorToast(data.response.data.message ?? 'Something went wrong');
                });
        },

        update() {
            this.updateOpportunity(this.opportunity)
                .then(() => {
                    this.$router.push({
                        name: "account-opportunity",
                    });
                    this.successToast();
                })
                .catch((data) => {
                    this.errorToast(data.response.data.message ?? 'Something went wrong');
                });
        },

        validatePublishOpportunity() {
            const requiredFieldKeys = [];
            if (!(this.opportunity.title?.length || false)) {
                requiredFieldKeys.push({
                    key: 'title',
                    value: 'Title'
                });
            }
            if (!(this.opportunity.role?.length || false)) {
                requiredFieldKeys.push({
                    key: 'role',
                    value: 'Role'
                });
            }
            if (!(this.opportunity.gender?.length || false)) {
                requiredFieldKeys.push({
                    key: 'gender',
                    value: 'Gender'
                });
            }
            if (!(this.opportunity.description.description?.length || false)) {
                requiredFieldKeys.push({
                    key: 'description',
                    value: 'Description'
                });
            }
            if (!(this.opportunity.info.nationality?.length || false)) {
                requiredFieldKeys.push({
                    key: 'nationality',
                    value: 'Nationality'
                });
            }
            if (!(this.opportunity.negotiation.salary?.length || false)) {
                requiredFieldKeys.push({
                    key: 'salary',
                    value: 'Salary'
                });
            }
            if (!(this.opportunity.negotiation.currency?.length || false)) {
                requiredFieldKeys.push({
                    key: 'currency',
                    value: 'Currency'
                });
            }
            if (!(this.opportunity.negotiation.pay_period?.length || false)) {
                requiredFieldKeys.push({
                    key: 'pay_period',
                    value: 'Pay Period'
                });
            }
            if (!(this.opportunity.info.employment_type?.length || false)) {
                requiredFieldKeys.push({
                    key: 'employment_type',
                    value: 'Employment Type'
                });
            }
            if (!(this.opportunity.info.work_place?.length || false)) {
                requiredFieldKeys.push({
                    key: 'work_place',
                    value: 'Work Place'
                });
            }
            if (!(this.opportunity.info.work_shift?.length || false)) {
                requiredFieldKeys.push({
                    key: 'work_shift',
                    value: 'Work Shift'
                });
            }
            if (!(this.opportunity.info.industry?.length || false)) {
                requiredFieldKeys.push({
                    key: 'industry',
                    value: 'Industry'
                });
            }
            if (!(this.opportunity.due_date?.length || false)) {
                requiredFieldKeys.push({
                    key: 'due_date',
                    value: 'Due Date'
                });
            }
            if (requiredFieldKeys.length > 0) {
                this.errorToast(requiredFieldKeys[0].value + ' field is required to publish');
                return false;
            } else {
                return true;
            }
        },

        checkForRePublish() {
            if ((this.$route.params.id || false) && !(this.$route.params.template || false)) {
                if (this.jobDueDate || false) {
                    if (this.jobDueDate < Date.now()) {
                        this.jobPublishBtnText = "Re Publish";
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            return false;
        },

        publishOpportunity() {
            if (!this.validatePublishOpportunity()) {
                return;
            }
            this.updateOpportunityInputByValue({ key: 'template', value: false });
            this.updateOpportunityInputByValue({ key: 'global', value: false });
            this.updateOpportunityInputByValue({ key: 'published', value: true });
            if (this.checkForRePublish()) {
                this.submitType = '';
            }
            document.getElementsByClassName('opprtunitysubmitbtn')[0].click();
        },

        saveAsTemplateOpportunity() {
            this.submitType = '';
            this.updateOpportunityInputByValue({ key: 'published', value: false });
            this.updateOpportunityInputByValue({ key: 'global', value: false });
            this.updateOpportunityInputByValue({ key: 'template', value: true });
            document.getElementsByClassName('opprtunitysubmitbtn')[0].click();
        },

        saveAsGlobalTemplateOpportunity() {
            this.submitType = '';
            this.updateOpportunityInputByValue({ key: 'published', value: false });
            this.updateOpportunityInputByValue({ key: 'global', value: true });
            this.updateOpportunityInputByValue({ key: 'template', value: true });
            document.getElementsByClassName('opprtunitysubmitbtn')[0].click();
        },

        handleOpportunityInput(event) {
            this.updateOpportunityInput(event);
        },

        handleAgeOpportunityInput(event) {
            this.updateAgeOpportunityInput(event);
        },

        handleAtollIslandRelation(event) {
            this.clearOldIslandValue();
            this.getIslandsByAtollName(event.target.value);
            this.updateOpportunityInput(event);
        },

        toggleShowJobboards() {
            this.showJobboards = !this.showJobboards;
        },

        handleApplyOption(e) {
            this.applyOption = e.target.value;
            this.handleOpportunityInput(e);
        },

        onFormComplete() {
            this.toggleShowJobboards()
        },

        beforeTabSwitch(value) {
            const needToFill = [];

            this.formValidationSchema[value].forEach((item) => {
                if (item.items.length > 0) {
                    item.items.forEach((child) => {
                        if ((this.opportunity[item.key][child.key] || '') == '') {
                            needToFill.push(child);
                        }
                    })
                } else {
                    if ((this.opportunity[item.key] || '') == '') {
                        needToFill.push(item);
                    }
                }
            });

            if (needToFill.length != 0) {
                const text = `${(needToFill.map((item) => item.name)).join(', ')} Field Required`;

                this.$toast.error(text, {
                    position: "top",
                });
                return false;
            }

            return true;
        },
    },
}