<template>
    <div class="col-md-4 card box">
        <img :src="photo.file?.path || ''" class="hover-shadow cursor" />
        <p>{{ photo.caption }}</p>
        <div class="float-right mt-3">
            <router-link
                :to="{
                    name: 'account-photo-edit',
                    params: { id: photo.id },
                }"
                ><i class="fas fa-edit"></i
            ></router-link>
            <div @click="destroy(photo.id)" class="d-inline-block mx-3 text-danger">
                <i class="fas fa-trash"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["photo"],

        methods: {
            ...mapActions("photo", ["getAccountPhotos", "destroyPhoto"]),

            destroy(id) {
                this.destroyPhoto(id).then(() => {
                    this.getAccountPhotos();
                    this.successToast();
                }).catch(() => this.errorToast());
            },
        },
    };
</script>