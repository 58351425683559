<template>
    <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <router-link to="/account">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item" v-for="item in items" :key="item.name">
                <router-link :to="item.route">{{ item.name }}</router-link>
            </li>
        </ol>
    </nav>
</template>

<script>
    export default {
        props: ['items']
    }
</script>