<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/team-roles',
                name: 'Team Roles',
            },
            {
                route: '/account/team-roles',
                name: 'Edit Team Role',
            },
        ]" />
        <team-role-form :isEdit="true" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import TeamRoleForm from "./components/TeamRoleForm.vue";

export default {
    components: {
        TeamRoleForm,
    },

    mounted() {
        this.getTeamRoleById(this.$route.params.id);
    },

    methods: {
        ...mapActions("teamRole", ["getTeamRoleById"]),
    },
};
</script>