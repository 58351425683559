<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/employer',
                    name: 'Employers',
                },
            ]"
        />
        <router-link
            :to="{ name: 'account-employer-create' }"
            class="btn btn-common btn-effect wow fadeInRight mt-3"
            data-wow-delay="0.9s"
            >Create</router-link
        >
        <div v-if="accounts.length" class="row mx-3 mb-5 mt-3">
            <employer-list-view-card
                v-for="employer in accounts"
                :key="employer.id"
                :employer="employer"
                :isAccount="true"
            />
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="0 Profile Found!" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import Empty from "@/components/utils/Empty.vue";
    import EmployerListViewCard from "@/components/employers/EmployerListViewCard.vue";

    export default {
        name: "account-employers",

        components: {
            Empty,
            EmployerListViewCard,
        },

        computed: {
            ...mapState("employer", ["accounts"]),
        },

        mounted() {
            this.getAccountEmployers();
        },

        methods: {
            ...mapActions("employer", ["getAccountEmployers"]),
        },
    };
</script>