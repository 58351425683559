import Dashboard from './../../views/account/dashboard/Index.vue';
import Profile from './../../views/account/profile/Index.vue';
import employerRoutes from './employer';
import certificateRoutes from './certificate';
import announcementRoutes from './announcement';
import awardRoutes from './award';
import photoRoutes from './photo';
import opportunityRoutes from './opportunity';
import teamRole from './team-role';

const routes = [
    {
        path: '/account',
        name: 'account',
        meta: {
            layout: 'account',
            title: 'Dashboard',
        },
        component: Dashboard
    },
    {
        path: '/account/profile',
        name: 'account-profile',
        meta: {
            layout: 'account',
            title: 'Profile',
        },
        component: Profile
    },
    ...employerRoutes,
    ...certificateRoutes,
    ...announcementRoutes,
    ...awardRoutes,
    ...photoRoutes,
    ...opportunityRoutes,
    ...teamRole,
];

export default routes;