<template>
    <div class="contact-right-area wow fadeIn">
        <h2>JobBank Office</h2>
        <div class="contact-info">
            <div class="single-contact">
                <div class="contact-icon">
                    <i class="fa fa-map-marker-alt"></i>
                </div>
                <p>{{ appContactInfo.info?.app_location || "" }}</p>
            </div>
            <div class="single-contact">
                <div class="contact-icon">
                    <i class="fa fa-phone"></i>
                </div>
                <p>Call Us : {{ appContactInfo.info?.app_phone || "" }}</p>
            </div>
            <div class="single-contact">
                <div class="contact-icon">
                    <i class="fab fa-whatsapp"></i>
                </div>
                <p>Whatsapp : {{ appContactInfo.info?.app_phone || "" }}</p>
            </div>
            <div class="single-contact">
                <div class="contact-icon">
                    <i class="fa fa-envelope"></i>
                </div>
                <p>
                    <a :href="'mailto:' + appContactInfo.info?.app_email"
                        >Email : {{ appContactInfo.info?.app_email || "" }}</a
                    >
                </p>
            </div>

            <div class="single-contact">
                <div class="share-bar">
                    <div class="float-left mt-2 mr-3">
                        <p>Follow Us</p>
                    </div>
                    <div class="float-left">
                        <a :href="appContactInfo.social?.app_facebook || '#'" title="" class="share-fb">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a :href="appContactInfo.social?.app_twitter || '#'" title="" class="share-twitter">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a :href="appContactInfo.social?.app_linkedin || '#'" title="" class="share-fb">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a :href="appContactInfo.social?.app_google || '#'" title="" class="share-google">
                            <i class="fab fa-google"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        computed: {
            ...mapState("appearance", ["appContactInfo"]),
        },

        mounted() {
            this.getAppContactInfo();
        },

        methods: {
            ...mapActions("appearance", ["getAppContactInfo"]),
        },
    };
</script>