import Api from "./api.js";

const END_POINT = '/appearance';

export default {

    heroSection() {
        return Api.get(END_POINT + '/hero-section');
    },

    clientWhoTrustSection() {
        return Api.get(END_POINT + '/client-who-trust-section');
    },

    howItWorkSection() {
        return Api.get(END_POINT + '/how-it-works-section');
    },

    whyChooseUsSection() {
        return Api.get(END_POINT + '/why-choose-us-section');
    },

    appDownlaodSection() {
        return Api.get(END_POINT + '/app-download-section');
    },

    appContactInfo() {
        return Api.get(END_POINT + '/app-contact-info');
    },

}