<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/team-members',
                name: 'Team Members',
            },
        ]" />

        <employer-team-members />
    </div>
</template>

<script>
import EmployerTeamMembers from "@/components/teams/EmployerTeamMembers.vue";

export default {
    components: {
        EmployerTeamMembers,
    },

};
</script>