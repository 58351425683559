<template>
    <div>
        <!--top bannner-->
        <app-top-banner title="Contact" />
        <!--top bannner-->

        <!-- contact form -->
        <section id="contact" class="section">
            <div class="contact-form">
                <div class="container">
                    <div class="row contact-form-area">
                        <div class="col-md-12 col-lg-6 col-sm-12">
                            <contact-form />
                        </div>
                        <div class="col-md-12 col-lg-6 col-sm-12">
                            <contact-info />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- contact form -->
    </div>
</template>

<script>
    import AppTopBanner from "@/components/utils/AppTopBanner.vue";
    import ContactForm from "./components/ContactForm.vue";
    import ContactInfo from "./components/ContactInfo.vue";

    export default {
        name: "contact",

        components: {
            AppTopBanner,
            ContactForm,
            ContactInfo,
        },
    };
</script>