<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/awards',
                name: 'Awards',
            },
        ]" />
        <div v-if="selectedEmployer || false">
            <div class="row align-items-center">
                <div class="col-md-12 text-right">
                    <router-link :to="{ name: 'account-award-create' }"
                        class="btn btn-common btn-effect wow fadeInRight mt-3" data-wow-delay="0.9s">Create</router-link>
                </div>
            </div>
            <div v-if="arrangeAwards.length || false" class="row mx-3 mb-5 mt-3">
                <award-card v-for="award in arrangeAwards" :key="award.id" :award="award" :isAccount="true" />
            </div>
            <div v-else class="row justify-content-center mb-5 mt-3">
                <empty :title="arrangeAwardsText" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";
import AwardCard from "@/components/employers/AwardCard.vue";

export default {
    components: {
        Empty,
        AwardCard,
    },

    computed: {
        ...mapState("employer", ["selectedEmployer"]),
        ...mapState("award", ["arrangeAwards", "arrangeAwardsText"]),
    },

    mounted() {
        if (this.selectedEmployer || false) {
            this.initAccountData(this.selectedEmployer.id);
        }
    },

    methods: {
        ...mapActions("award", [
            "initAccountData",
        ]),
    },
};
</script>