<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/opportunities',
                    name: 'Opportunities',
                },
                {
                    route: '/account/opportunities',
                    name: 'Edit Opportunity',
                },
            ]"
        />
        <div v-if="laoding" class="card box">
            <p>Loading...</p>
        </div>
        <opportunity-form v-else :isEdit="true" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import OpportunityForm from "./components/OpportunityForm.vue";

    export default {
        components: {
            OpportunityForm,
        },

        data: () => ({
            laoding: false,
        }),

        mounted() {
            // this.getProps();
        },

        methods: {
            ...mapActions("opportunity", ["getAccountOpportunityById"]),

            async getProps() {
                this.laoding = true;
                await this.getAccountOpportunityById(this.$route.params.id);
                this.laoding = false;
            }
        },
    };
</script>