<template>
    <div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12 col-md-12">
                                <div class="numbers text-left">
                                    <div style="font-size: 16px; padding-left: 10px; padding-top: 10px">
                                        Job Oppertunities
                                        <span style="font-weight: 500; font-size: 18px">
                                            - {{ opportunityStatistics.total_opportunities || 0 }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-6 col-md-6 text-right">
                                <a href="#" class="btn btn-common btn-dash">Add Job Opening</a>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <hr class="dashed-line" />
                        <div class="row">
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Published">
                                <a href="#">
                                    <span class="stat-bg">
                                        <i class="fas fa-briefcase"></i>
                                        {{ opportunityStatistics.published || 0 }}
                                    </span>
                                    <div class="stat-text">Published</div>
                                </a>
                            </div>
                            <div
                                class="col stat-pointers"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Unpublished"
                            >
                                <a href="#">
                                    <span class="stat-bg">
                                        <i class="fas fa-briefcase"></i>
                                        {{ opportunityStatistics.unpublished || 0 }}
                                    </span>
                                    <div class="stat-text">Unpublished</div>
                                </a>
                            </div>
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Internal">
                                <a href="#">
                                    <span class="stat-bg">
                                        <i class="fas fa-briefcase"></i>
                                        {{ opportunityStatistics.expired || 0 }}
                                    </span>
                                    <div class="stat-text">Expired</div>
                                </a>
                            </div>
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Internal">
                                <a href="#">
                                    <span class="stat-bg">
                                        <i class="fas fa-briefcase"></i>
                                        {{ opportunityStatistics.templates || 0 }}
                                    </span>
                                    <div class="stat-text">Template</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-6 col-md-6">
                                <div style="font-size: 16px; padding-top: 10px; padding-left: 10px">
                                    Total Applicants
                                    <span style="font-weight: 500; font-size: 18px">- 0</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 text-right">
                                <a href="#" class="btn btn-common btn-dash">Add Applicant</a>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <hr class="dashed-line" />
                        <div class="row">
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Applied">
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">New</div>
                                </a>
                            </div>
                            <div
                                class="col stat-pointers"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Shortlisted"
                            >
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Shortlisted</div>
                                </a>
                            </div>
                            <div
                                class="col stat-pointers"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Shortlisted"
                            >
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Scheduled Interviews</div>
                                </a>
                            </div>
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Interview">
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Interview</div>
                                </a>
                            </div>
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Offered">
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Offered</div>
                                </a>
                            </div>
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Hired">
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Hired</div>
                                </a>
                            </div>
                            <div class="col stat-pointers" data-toggle="tooltip" data-placement="top" title="Hired">
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Rejected</div>
                                </a>
                            </div>
                            <div
                                class="col stat-pointers"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Talent Pool"
                            >
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Viewed</div>
                                </a>
                            </div>
                            <div
                                class="col stat-pointers"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Talent Pool"
                            >
                                <a href="#">
                                    <span class="stat-bg"><i class="fa fa-user"></i>0</span>
                                    <div class="stat-text">Saved</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-header">
                        <div class="card-text">
                            <h6 class="card-title">Reviews (Company)</h6>
                        </div>
                    </div>
                    <div class="card-body table-responsive scroll" style="min-height: 230px">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Review</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="3" class="text-center py-5">
                                        <p>No reviews found</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <hr />
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="stats"><i class="far fa-calendar"></i> <a href="#">View All</a></div>
                            </div>
                            <div class="col-sm-5">
                                <div class="pull-right">
                                    <a href="#">
                                        <button class="btn btn-success btn-round btn-icon btn-sm">
                                            <i class="nc-icon nc-simple-add" style="font-weight: 800"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-header">
                        <div class="card-text">
                            <h6 class="card-title">Upcoming Interviews</h6>
                        </div>
                    </div>
                    <div class="card-body table-responsive scroll" style="min-height: 230px">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Applicant Name</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="3" class="text-center py-5">
                                        <p>No upcoming interviews</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <hr />
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="stats"><i class="far fa-calendar"></i> <a href="#">View All</a></div>
                            </div>
                            <div class="col-sm-5">
                                <div class="pull-right">
                                    <a href="#">
                                        <button class="btn btn-success btn-round btn-icon btn-sm">
                                            <i class="nc-icon nc-simple-add" style="font-weight: 800"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title">Setup Your Account</h6>
                        <div class="progress-container progress-success">
                            <div class="progress" style="height: 25px; margin-bottom: 10px; border-radius: 50px">
                                <div
                                    class="progress-bar progress-bar-success"
                                    role="progressbar"
                                    aria-valuenow="20"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style="width: 20%"
                                >
                                    <span class="progress-value">20%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="list-unstyled" style="font-size: 16px; line-height: 35px; margin-left: 20px">
                            <!-- <li class="text-success"><i class="fas fa-check"></i> Email Confirmed</li> -->
                            <li>
                                <router-link to="/account/employer">
                                    <i class="fas fa-plus"></i> Add Employer Profile
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/account/awards">
                                    <i class="fas fa-plus"></i> Build Career Page
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/account/opportunities">
                                    <i class="fas fa-plus"></i> Post a Job
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/account/announcements">
                                    <i class="fas fa-plus"></i> Announcements
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">
                            <small>Subscriptions</small>
                            <span class="pull-right"
                                ><small
                                    ><small
                                        ><a href="#"><button class="btn btn-success btn-sm">Renew</button></a></small
                                    ></small
                                ></span
                            >
                        </h4>
                        <div class="progress-container progress-success">
                            <div class="progress" style="height: 55px; margin-bottom: 10px; border-radius: 5px">
                                <div
                                    class="progress-bar progress-bar-warning"
                                    role="progressbar"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style="width: 87%"
                                >
                                    <span class="progress-value"><strong>13 Days</strong></span>
                                </div>
                            </div>
                            <p><small>After you trial ends, your plan will be downgraded to the free plan.</small></p>
                        </div>
                    </div>
                    <div class="card-body"></div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

        computed: {
            ...mapState("opportunity", ["opportunityStatistics"]),
        },

        mounted() {
            this.getOpportunityStatistics();
        },

        methods: {
            ...mapActions("opportunity", [
                "getOpportunityStatistics",
            ]),
        },
    };
</script>