<template>
    <div class="listview-wrapper min-w-100">
        <div class="row my-2">
            <div class="col-lg-2 col-md-2 col-xs-12">
                <div v-if="opportunity.employer_logo.length || false" class="job-logo w-100">
                    <img :src="opportunity.employer_logo" alt="" class="d-block" />
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="job-details">
                    <h3 class="d-block">{{ opportunity.title || "" }}</h3>
                    <span>{{ opportunity.employer_name || "" }}</span>
                    <p v-if="opportunity.address.length || false" class="company-location">
                        <i class="fas fa-map-marker-alt"></i>
                        {{ opportunity.address || "" }}
                    </p>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-12 text-left mt-4">
                <span v-if="opportunity.employment_type.length || false" class="d-block pb-4">
                    <i class="fas fa-archive"></i> {{ opportunity.employment_type }}
                </span>
                <span v-if="opportunity.salary.length || false" class="mt-2">
                    <i class="fas fa-money-bill-alt"></i>
                    {{ opportunity.salary + " " + opportunity.currency }}
                </span>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-12 text-left mt-4">

                <span v-if="(isAccount || false) && (opportunity.template || false) && (opportunity.global || false)">
                    Global Template
                </span>
                <span v-else-if="(isAccount || false) && opportunity.template || false"> Template </span>
                <span v-else-if="opportunity.has_due_date || false">
                    <span v-if="opportunity.is_expired || false"> Expired </span>
                    <span v-else class="d-block pb-4">
                        <i class="far fa-clock"></i>
                        {{ opportunity.due_date_human || "" }}
                    </span>

                    <span v-if="opportunity.publish_date.length || false" class="d-block">
                        <i class="far fa-clock"></i> Posted {{ opportunity.publish_date }}
                    </span>
                    <span class="d-block" v-else> Not Published Yet </span>
                </span>
                <span v-else>No Due Date</span>
            </div>
            <div class="col-lg-3 browse-btn col-md-3 col-xs-12 text-center">
                <div v-if="isAccount || false" class="location mb-2 col-md-10 float-right">
                    <p v-if="!opportunity.is_expired" class="float-right text-danger ml-4" @click="destroy(opportunity.id)">
                        <i class="far fa-trash-alt"></i> Delete
                    </p>
                    <router-link :to="{
                        name: 'account-opportunity-edit',
                        params: { id: opportunity.id },
                    }">
                        <p class="float-right text-success"><i class="far fa-edit"></i> Edit</p>
                    </router-link>
                </div>
                <router-link :to="{
                    name: 'opportunity-show',
                    params: { id: opportunity.id },
                }" class="btn float-right">Browse Job</router-link>
            </div>
        </div>

        <hr v-if="isAccount || false" />
    </div>
</template>

<style scoped>
.listview-wrapper .job-details {
    margin: 0 !important;
}
</style>

<script>
import { mapActions } from "vuex";

export default {
    props: ["opportunity", "isAccount"],

    methods: {
        ...mapActions("opportunity", ["getAccountOpportunities", "destroyOpportunity"]),

        destroy(id) {
            if (this.isAccount || false) {
                this.destroyOpportunity(id).then(() => {
                    this.getAccountOpportunities();
                    this.successToast();
                });
            }
        },
    },
};
</script>