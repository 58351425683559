<template>
    <div v-if="accounts.length ?? false">
        <form @submit.prevent="action">
            <div class="row">
                <div class="col-lg-8 col-md-6 card box mx-4">
                    <h6>{{ isEdit || false ? "Edit" : "Create" }} Announcement</h6>
                    <div v-if="!(isEdit || false)" class="form-group">
                        <label>Select Employer Profile</label>
                        <select
                            class="form-control"
                            name="employer_id"
                            :value="announcement.employer_id"
                            @input="handleAnnouncementInput"
                        >
                            <option v-for="option in accounts" :key="option.id" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Announcement Title</label>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Announcement Title"
                                name="title"
                                :value="announcement.title"
                                @input="handleAnnouncementInput"
                                required
                                autofocus
                            />
                        </div>
                        <div class="form-group col-md-6">
                            <label>Announcement Expired Date</label>
                            <input
                                type="date"
                                class="form-control"
                                placeholder="Announcement Expired Date"
                                name="expired_date"
                                :value="announcement.expired_date"
                                @input="handleAnnouncementInput"
                                required
                                autofocus
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Announcement Descripton</label>
                        <textarea
                            class="form-control"
                            placeholder="Announcement Descripton"
                            name="description"
                            :value="announcement.description"
                            @input="handleAnnouncementInput"
                            rows="4"
                        ></textarea>
                    </div>
                    <button type="submit" class="btn btn-common log-btn">
                        {{ isEdit || false ? "Update" : "Save" }}
                    </button>
                </div>
                <div class="col-lg-3 col-md-5">
                    <div v-if="previewfile" class="mb-4">
                        <p class="text-muted mb-2">Preview</p>
                        <a class="bbutton" :href="previewfile.path || '#'" target="_blank">
                            <i class="fas fa-file-pdf"></i> View File
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </a>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Announcement File</h6>
                            <div class="card card-plain">
                                <div class="card-body">
                                    <input type="file" class="form-control" @input="onFileChanged" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div v-else class="row mb-5 mt-3">
        <div class="col-md-12">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import Empty from "@/components/utils/Empty.vue";

    export default {
        props: {
            isEdit: Boolean,
        },

        components: { Empty },

        computed: {
            ...mapState("announcement", ["announcement", "previewfile"]),
            ...mapState("employer", ["accounts", "selectedEmployer"]),
        },

        mounted() {
            this.clearAnnouncementForm(this.selectedEmployer.id || '');
            if(this.accounts.length == 0) {
                this.getAccountEmployers();
            }
        },

        methods: {
            ...mapActions("announcement", [
                "createAnnouncement",
                "updateAnnouncement",
                "onFileChanged",
                "updateAnnouncementInput",
                "clearAnnouncementForm",
            ]),
            ...mapActions("employer", ["getAccountEmployers"]),

            action() {
                this.isEdit ? this.update() : this.create();
            },

            create() {
                this.createAnnouncement(this.announcement)
                    .then(() => {
                        this.$router.push({
                            name: "account-announcement",
                        });
                        this.successToast();
                    })
                    .catch(() => {
                        this.errorToast();
                    });
            },

            update() {
                this.updateAnnouncement(this.announcement)
                    .then(() => {
                        this.$router.push({
                            name: "account-announcement",
                        });
                        this.successToast();
                    })
                    .catch(() => {
                        this.errorToast();
                    });
            },

            handleAnnouncementInput(event) {
                this.updateAnnouncementInput(event);
            },
        },
    };
</script>