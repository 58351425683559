<template>
    <div class="">
        <opportunity-preview />
        <br />
        <br />
        <div>
            <label>Select Jobboard to publish</label>
        </div>
        <div class="d-flex align-items-center">
            <div class="jobboard-item jobboard-item-selected">
                <div class="jobboard__image py-2">
                    <img src="/assets/img/jobhunt.png" alt="" />
                </div>
                <h6>Job Hunt</h6>
            </div>
            <div
                v-for="jobboard in jobboards"
                :key="jobboard.id"
                class="jobboard-item"
                :class="{
                    'jobboard-item-selected': selectedJobboards.filter((e) => e == jobboard.id).length || false,
                }"
                @click="() => setSelectedJobboardsOnForm(jobboard.id)"
            >
                <div v-if="jobboard.image" class="jobboard__image py-2">
                    <img :src="jobboard.image" alt="" />
                </div>
                <h6>{{ jobboard.name || "" }}</h6>
                <p class="text-danger fw-bold">Credit: {{ jobboard.credit || "" }}</p>
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<style scoped>
    .fw-bold {
        font-weight: bold;
    }
</style>

<script>
    import { mapState, mapActions } from "vuex";
    import OpportunityPreview from "./OpportunityPreview.vue";

    export default {

        data() {
            return {
                selectedJobboards: [],
            };
        },

        components: { OpportunityPreview },

        computed: {
            ...mapState("jobboard", ["jobboards"]),
            ...mapState("opportunity", ["opportunity"]),
        },

        mounted() {
            this.getJobboards();
        },

        methods: {
            ...mapActions("jobboard", ["getJobboards"]),
            ...mapActions("opportunity", ["setSelectedJobboards"]),

            setSelectedJobboardsOnForm(id) {
                let temp = this.selectedJobboards.filter((e) => e == id);
                if (temp.length) {
                    this.selectedJobboards = this.selectedJobboards.filter((e) => e != id);
                } else {
                    this.selectedJobboards.push(id);
                }
                this.setSelectedJobboards(this.selectedJobboards);
            }
        },
    };
</script>