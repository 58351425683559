export default {
    
    methods: {
        successToast(message) {
            this.$toast.success(message || 'Successfully Done', {
                position: "top",
            });
        },

        errorToast(message) {
            this.$toast.error(message || 'Something went wrong', {
                position: "top",
            });
        },

        
        showLocationFormPopup() {
            const popup = document.getElementById('userLocationForm');
            popup.style.display = "block";
        },

        hideLocationFormPopup() {
            const popup = document.getElementById('userLocationForm');
            popup.style.display = "none";
        },
    },
};

