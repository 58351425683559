<template>
    <div class="job-single-sec style3">
        <div class="job-head-wide">
            <div class="row">
                <div class="col-lg-12">
                    <div class="job-single-head3 emplye">
                        <!-- <div class="job-thumb" v-if="employer.file?.path || false">
                            <img :src="employer.file.path" alt="" />
                        </div> -->

                        <div class="job-single-info3">
                            <h3>
                                {{ opportunity.title }}
                                <span class="verified-badge" title="Verified Employer" data-tippy-placement="top">
                                    <i class="la la-check"></i>
                                </span>
                            </h3>
                            <span class="mr-3" v-if="opportunity.employer.location || false">
                                <i class="fas fa-map-marker-alt"></i>
                                {{
                                    `${opportunity.employer.location.address || ""}, ${opportunity.employer.location.atoll.name || ""}, ${
                                        opportunity.employer.location.island.name || ""
                                    }`
                                }}
                            </span>
                            <span class="mr-3" v-if="opportunity.employer.website">
                                <i class="fas fa-link"></i> {{ opportunity.employer.website || "" }}
                            </span>
                            <span class="mr-3" v-if="opportunity.employer.email">
                                <i class="fas fa-envelope"></i> {{ opportunity.employer.email || "" }}
                            </span>
                            <span class="mr-3" v-if="opportunity.employer.phone"
                                ><i class="fas fa-phone"></i> {{ opportunity.employer.phone || "" }}
                            </span>
                            <!-- <ul class="tags-jobs">
                                <li>
                                    <i class="fas fa-archive"></i>
                                    Jobs Posted: {{ employer.job_posted || 0 }}
                                </li>
                                <li>
                                    <i class="fas fa-paint-brush"></i>
                                    Open Jobs: {{ employer.job_published || 0 }}
                                </li>
                                <li>
                                    <i class="fas fa-users"></i>
                                    Followers {{ employer.follower }}
                                </li>
                                <li>
                                    <i class="fas fa-eye"></i> Views
                                    {{ employer.view }}
                                </li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["opportunity"],
};
</script>