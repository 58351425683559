<template>
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="form-group">
                <label>Apply Options</label>
                <select class="form-control" name="additional.apply_option"
                    :value="opportunity.additional?.apply_option ?? ''" @change="handleApplyOption">
                    <option value="internal">internal</option>
                    <option value="email">Email</option>
                    <option value="ats">ATS</option>
                    <option value="external">External URL</option>
                </select>
            </div>
        </div>
        <div v-if="applyOption == 'email'" class="col-md-6">
            <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-control" placeholder="Email" name="additional.apply_option_email"
                    :value="opportunity.additional.apply_option_email" @input="handleOpportunityInput" />
            </div>
        </div>
        <div v-if="applyOption == 'external'" class="col-md-6">
            <div class="form-group">
                <label>External URL</label>
                <input type="text" class="form-control" placeholder="External URL" name="additional.apply_option_url"
                    :value="opportunity.additional.apply_option_url" @input="handleOpportunityInput" />
            </div>
        </div>
        <div class="col-md-12 mx-4">
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="people_with_disabilities"
                    name="additional.people_with_disabilities"
                    :value="opportunity.additional?.people_with_disabilities ?? ''"
                    :checked="opportunity.additional?.people_with_disabilities ?? false" @input="handleOpportunityInput" />
                <label class="form-check-label" for="people_with_disabilities">
                    This job is targeted to people with disabilities
                </label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="moms_working_from_home"
                    name="additional.moms_working_from_home" :value="opportunity.additional?.moms_working_from_home ?? ''"
                    :checked="opportunity.additional?.moms_working_from_home ?? false" @input="handleOpportunityInput" />
                <label class="form-check-label" for="moms_working_from_home">
                    This job is targeted to Moms working from home
                </label>
            </div>
        </div>
    </div>
</template>


<script>
import OpportunityMixin from '@/mixins/OpportunityMixin.js';

export default {
    props: {
        isEdit: Boolean,
    },

    mixins: [OpportunityMixin],

};
</script>