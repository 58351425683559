import Api from "./api.js";

const END_POINT = '/opportunities';
const ACCOUNT_END_POINT = '/account' + END_POINT;

export default {

    all(page) {
        return Api.get(`${END_POINT}?page=${page || 1}`);
    },

    accounts() {
        return Api.get(ACCOUNT_END_POINT);
    },

    recent() {
        return Api.get(END_POINT + '/recent');
    },

    search({ query, atoll, category_id, employment_type }) {
        const url = `${END_POINT}/search?query=${query}&atoll=${atoll}&category_id=${category_id}&employment_type=${employment_type}`;
        console.log(url);
        return Api.get(url);
    },

    store(data) {
        return Api.post(ACCOUNT_END_POINT, data);
    },

    find(id) {
        return Api.get(END_POINT + `/${id}`);
    },

    findOnAccount(id) {
        return Api.get(ACCOUNT_END_POINT + `/${id}`);
    },

    update(data) {
        return Api.put(ACCOUNT_END_POINT + `/${data.id}`, data);
    },

    destroy(id) {
        return Api.delete(ACCOUNT_END_POINT + `/${id}`);
    },

    getStatistics() {
        return Api.get(ACCOUNT_END_POINT + `/statistics`);
    }

};