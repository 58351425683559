<template>
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="inner-header">
                        <h3>{{ title || "" }}</h3>
                        <p>{{ subtitle || "" }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "app-top-banner",

        props: {
            title: String,
            subtitle: String,
        },
    };
</script>