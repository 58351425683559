import Api from "./api.js";

const END_POINT = '/employers';
const ACCOUNT_END_POINT = '/account' + END_POINT;

export default {

    all(page) {
        return Api.get(`${END_POINT}?page=${page || 1}`);
    },

    filter(filter) {
        let url = END_POINT + '/filter?';
        if (filter) {
            console.log(filter);
            for (const property in filter) {
                if (property) {
                    url += `${property}=${filter[property]}&`;
                }
            }
        }
        console.log(url);
        return Api.get(url);
    },

    alphaFilter(alpha) {
        const url = `${END_POINT}/filter/alpha?alpha=${alpha}`;
        return Api.get(url);
    },

    accounts() {
        return Api.get(ACCOUNT_END_POINT);
    },

    store(data) {
        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        return Api.post(ACCOUNT_END_POINT, formData, {
            headers: {
                'Content-Type': 'multipart/form-data boundary=' + Math.random().toString(2),
            }
        });
    },

    find(id) {
        return Api.get(END_POINT + `/${id}`);
    },

    update(data) {
        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        console.log(data['industries']);
        for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }
        formData.append('_method', 'PUT');
        return Api.post(ACCOUNT_END_POINT + `/${data.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data boundary=' + Math.random().toString(2),
            }
        });
    },

    destroy(id) {
        return Api.delete(ACCOUNT_END_POINT + `/${id}`);
    },

    count() {
        return Api.get(END_POINT + '/count')
    },

    storeUserLocation(data) {
        return Api.post(ACCOUNT_END_POINT + '/locations/store', data);
    },

    getUserLocations() {
        return Api.get(ACCOUNT_END_POINT + '/locations/index');
    },

    addEmployerTeamMember(data) {
        return Api.post(ACCOUNT_END_POINT + '/team-member/store', data);
    },

    removeEmployerTeamMember({teamMemberId}) {
        return Api.get(ACCOUNT_END_POINT + `/team-member/${teamMemberId}/remove`,);
    },

};