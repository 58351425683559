import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import BreadcrumbComponent from "@/views/account/layouts/components/Breadcrumb.vue";

import GlobalMixin from './mixins/GlobalMixin';

import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA9HKi3runnckcXN3EU5XkTV2DIeOv2aLI",
    authDomain: "jobportal-jobbank.firebaseapp.com",
    projectId: "jobportal-jobbank",
    storageBucket: "jobportal-jobbank.appspot.com",
    messagingSenderId: "602887075738",
    appId: "1:602887075738:web:c4a7269e3af907e3be2c23"
};

initializeApp(firebaseConfig);


const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toaster);
app.component('Breadcrumb', BreadcrumbComponent);
app.mixin(GlobalMixin);
app.mount('#app');
