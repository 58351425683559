<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/team-roles',
                name: 'Team Roles',
            },
        ]" />
        <div v-if="selectedEmployer || false">
            <div class="row align-items-center">
                <div class="col-md-12 text-right">
                    <router-link :to="{ name: 'account-team-role-create' }"
                        class="btn btn-common btn-effect wow fadeInRight" data-wow-delay="0.9s">Create</router-link>
                </div>
            </div>
            <div v-if="arrangeTeamRoles.length || false" class="row mx-3 mb-5 mt-3">
                <div v-for="teamRole in arrangeTeamRoles" :key="teamRole.id" class="col-md-4 card">
                    <div class="d-flex justify-content-between mt-3">
                        <div>
                            <p class="text-dark">{{ teamRole.title || '' }}</p>
                        </div>
                        <div class="location mb-2 d-flex">
                            <router-link :to="{
                                name: 'account-team-role-edit',
                                params: { id: teamRole.id },
                            }">
                                <div class="color-primary"><i class="fas fa-edit"></i></div>
                            </router-link>
                            <div @click="destroy(teamRole.id)" class="text-danger ml-3">
                                <i class="fas fa-trash"></i>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0 mb-2">
                </div>
            </div>
            <div v-else class="row justify-content-center mb-5 mt-3">
                <empty :title="arrangeTeamRolesText" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";

export default {
    components: {
        Empty,
    },

    computed: {
        ...mapState("employer", ["selectedEmployer"]),
        ...mapState("teamRole", ["arrangeTeamRoles", "arrangeTeamRolesText"]),
    },

    mounted() {
        if (this.selectedEmployer || false) {
            this.initAccountData(this.selectedEmployer.id);
        }
    },

    methods: {
        ...mapActions("teamRole", [
            "initAccountData",
            "destroyTeamRole"
        ]),

        destroy(id) {
            this.destroyTeamRole(id).then(() => {
                this.initAccountData(this.selectedEmployer.id);
                this.successToast();
            }).catch(() => this.errorToast());
        },

    },
};
</script>