import Api from "./api.js";

const END_POINT = '/jobboards';

export default {

    getJobboards() {
        return Api.get(END_POINT);
    },

};