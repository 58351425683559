<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/opportunities',
                    name: 'Opportunities',
                },
                {
                    route: '/account/opportunities',
                    name: 'Create Opportunity',
                },
            ]"
        />
        <opportunity-form :isEdit="false" />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import OpportunityForm from "./components/OpportunityForm.vue";

    export default {
        components: {
            OpportunityForm,
        },

        computed: {
            ...mapState("opportunity", ["selectedEmployerId"]),
        },

        mounted() {
            if (!(this.selectedEmployerId || false)) {
                this.$router.push({
                    name: "account-opportunity",
                });
            }
        },
    };
</script>

