<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/awards',
                    name: 'Awards',
                },
                {
                    route: '/account/awards',
                    name: 'Edit Award',
                },
            ]"
        />
        <award-form :isEdit="true" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import AwardForm from "./components/AwardForm.vue";

    export default {
        components: {
            AwardForm,
        },

        mounted() {
            this.getAwardById(this.$route.params.id);
        },

        methods: {
            ...mapActions("award", ["getAwardById"]),
        },
    };
</script>