<template>
    <div class="job-overview">
        <h3>Overview</h3>
        <ul>
            <li>
                <i class="fas fa-users"></i>
                <h3>Team Size</h3>
                <span> {{ employer.team_size || 1 }}</span>
            </li>
            <li>
                <i class="far fa-clock"></i>
                <h3>Since</h3>
                <span>{{ employer.since || "" }}</span>
            </li>
            <li>
                <i class="fas fa-bars"></i>
                <h3>Sector</h3>
                <span>{{ employer.employment_sector }}</span>
            </li>
            <li>
                <i class="fas fa-briefcase"></i>
                <h3>Ownership Type</h3>
                <span>{{ employer.ownership_type || "" }}</span>
            </li>
            <li>
                <i class="fas fa-puzzle-piece"></i>
                <h3>Industries</h3>
                <span v-for="industry in employer.industries" :key="industry.id">{{ industry.title || "" }}</span>
            </li>
            <li>
                <i class="fas fa-archive"></i>
                <h3>Employment Types</h3>
                <span v-for="employment_type in employer.employment_types" :key="employment_type.id">
                    {{ employment_type.title || "" }}
                </span>
            </li>
            <li>
                <i class="fab fa-weixin"></i>
                <h3>Affiliations</h3>
                <span>{{ employer.affiliations }}</span>
            </li>
            <li>
                <i class="fas fa-wrench"></i>
                <h3>Certifications</h3>
                <span v-for="certificate in employer.certificates" :key="certificate.id">{{ certificate.name }}</span>
            </li>
            <li>
                <i class="far fa-calendar-times"></i>
                <h3>Office Hours</h3>
                <span>{{ employer.office_start_hour }} - {{ employer.office_end_hour }}</span>
            </li>
            <li>
                <i class="fas fa-money-bill-alt"></i>
                <h3>Average Salary</h3>
                <span>1200$ - 30000$</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: ["employer"],
    };
</script>