import Api from "./api.js";

const END_POINT = 'option-property';

export default {
    all() {
        return Api.get(END_POINT);
    },

    getIslandsByAtollName(atoll) {
        return Api.get(END_POINT + '/atoll/' + atoll || '');
    },
};