<template>
    <form @submit.prevent="action">
        <div v-if="loading" class="card box">
            <p>Loading...</p>
        </div>
        <div v-else class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Company Profile</h5>
                        <br />
                        <div class="card card-plain">
                            <div class="form-group">
                                <label>Company Name <span>*</span></label>
                                <input type="text" class="form-control" placeholder="Type Company Name" name="name"
                                    :value="employer.name" @input="updateEmployerInput" required />
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="company_website">Website</label>
                                    <input type="text" class="form-control" placeholder="http://example.com" name="website"
                                        :value="employer.website" @input="updateEmployerInput" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Category/Field Of Work</label>
                                    <select class="form-control" name="category_id" :value="employer.category_id"
                                        @input="updateEmployerInput">
                                        <option v-for="option in options.category" :key="option.id" :value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label>Company Address</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Type Company Address"
                                        name="address"
                                        :value="employer.address"
                                        @input="updateEmployerInput"
                                    />
                                </div>
                                <div class="form-group col-md-6">
                                    <label>City/Atoll</label>
                                    <select
                                        class="form-control"
                                        name="atoll"
                                        :value="employer.atoll"
                                        @input="handleAtollIslandRelation"
                                    >
                                        <option v-for="option in options.atoll" :key="option.id" :value="option.name">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Island/Ward</label>
                                    <select
                                        class="form-control"
                                        name="island"
                                        :value="employer.island"
                                        @input="updateEmployerInput"
                                    >
                                        <option v-if="!(employer.atoll || false)" disabled>Please Select Atoll</option>
                                        <option v-else-if="(options.island || []).length == 0" disabled>
                                            Loading...
                                        </option>
                                        <option
                                            v-for="option in options.island || []"
                                            :key="option.id"
                                            :value="option.name"
                                        >
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label>Company Email</label>
                                    <input type="email" class="form-control" placeholder="Type Company Email" name="email"
                                        :value="employer.email" @input="updateEmployerInput" required />
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Company Phone</label>
                                    <input type="text" class="form-control" placeholder="Type Company Phone" name="phone"
                                        :value="employer.phone" @input="updateEmployerInput" required autofocus />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="company_email">Team Size</label>
                                    <input type="number" class="form-control" placeholder="Number Of Stuff" name="team_size"
                                        :value="employer.team_size" @input="updateEmployerInput" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="company_telephone">Since</label><input type="number" min="1900" max="2099"
                                        class="form-control" placeholder="Type Business Start Year" name="since"
                                        :value="employer.since" @input="updateEmployerInput" />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <div class="d-flex">
                                        <label>Locations</label>
                                        <p class="text-primary d-inline-block mx-2" @click="showLocationFormPopup"><i
                                                class="fa fa-plus"></i></p>
                                    </div>
                                    <select class="form-control" name="address" :value="employer.address"
                                        @input="updateEmployerInput" required>
                                        <option v-if="(userLocations || []).length == 0" disabled>
                                            Click + to create one
                                        </option>
                                        <option v-for="option in userLocations || []" :key="option.id" :value="option.id">
                                            {{ option.address }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Employment Sector</label>
                                    <select class="form-control" name="employment_sector"
                                        :value="employer.employment_sector" @input="updateEmployerInput" required>
                                        <option v-for="option in options.employment_sector" :key="option.id"
                                            :value="option.title">
                                            {{ option.title }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Ownership type</label>
                                    <select class="form-control" name="ownership_type" :value="employer.ownership_type"
                                        @input="updateEmployerInput" required>
                                        <option v-for="option in options.ownership_type" :key="option.id"
                                            :value="option.title">
                                            {{ option.title }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label>Industries</label>
                                    <v-select label="title" v-model="handleEmployersIndustries"
                                        :options="options.industry || []" :reduce="(option) => option?.id || ''"
                                        class="form-control" multiple></v-select>
                                </div>
                                <div class="form-group col-md-12">
                                    <label>Employment types</label>
                                    <v-select label="title" v-model="handleEmployerTypes"
                                        :options="options.employment_type || []" :reduce="(option) => option?.id || ''"
                                        class="form-control employertypes" multiple></v-select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>Currency</label>
                                    <select class="form-control" name="currency" :value="employer.currency"
                                        @input="updateEmployerInput" required>
                                        <option v-for="option in options.currency" :key="option.id"
                                            :value="option.currency">
                                            {{ option.currency }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_email">Affiliations</label>
                                    <input type="text" class="form-control" placeholder="Type Affiliations"
                                        name="affiliations" :value="employer.affiliations" @input="updateEmployerInput" />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_telephone">Office Hours</label>
                                    <label>Office Start/End Hour</label>
                                    <div class="input-group">
                                        <input type="time" class="form-control" name="office_start_hour"
                                            :value="employer.office_start_hour" @input="updateEmployerInput" autofocus />
                                        <input type="time" class="form-control" name="office_end_hour"
                                            :value="employer.office_end_hour" @input="updateEmployerInput" autofocus />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Description</label>
                                <ckeditor :editor="editor" :model-value="employer.description" :value="employer.description"
                                    @input="updateEmployerDescriptionInput"></ckeditor>
                            </div>
                            <button type="submit" class="btn btn-common log-btn">
                                {{ isEdit || false ? "Update" : "Save" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="previewfile" class="mb-4">
                    <p class="text-muted mb-2">Preview</p>
                    <a class="bbutton" :href="previewfile.path || '#'" target="_blank">
                        <i class="fas fa-file-pdf"></i> View File
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </a>
                    <img :src="previewfile.path || ''" alt="" class="d-block img-fluid" />
                </div>
                <div class="card">
                    <div class="card-body">
                        <p class="card-title">Company Logo</p>
                        <div class="card card-plain">
                            <div class="card-body">
                                <input type="file" class="form-control pt-10" @input="onFileChanged" />
                                <label><small>Logo must be in jpg or png (less than 2 mb)</small></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-popup-area signin-popup-box" id="userLocationForm">
                    <div class="account-popup">
                        <div class="page-login-form">
                            <span class="close-popup" @click="hideLocationFormPopup"><i class="fa fa-close"></i></span>
                            <user-location-form />
                        </div>
                    </div>
                </div>
                <!-- <employer-team-members v-if="this.$route.params.id" /> -->
            </div>
        </div>
    </form>
</template>

<style scoped>
.account-popup {
    padding: 0 !important;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue";
import UserLocationForm from "./UserLocationForm.vue";
// import EmployerTeamMembers from "../../../../components/teams/EmployerTeamMembers.vue";

export default {
    props: {
        isEdit: Boolean,
    },

    components: {
        vSelect,
        ckeditor: CKEditor.component,
        UserLocationForm,
        // EmployerTeamMembers,
    },

    data: () => ({
        loading: false,
        editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        handleEmployerTypes: [],
        handleEmployersIndustries: [],
    }),

    watch: {
        handleEmployerTypes(val) {
            this.updateEmployerMultipleSelect({ key: 'employment_types', value: val || '' });
        },

        handleEmployersIndustries(val) {
            this.updateEmployerMultipleSelect({ key: 'industries', value: val || '' });
        }
    },

    computed: {
        ...mapState("optionProperty", ["options"]),
        ...mapState("employer", ["employer", "previewfile", "userLocations"]),
    },

    mounted() {
        this.clearEmployerProfileForm();
        this.getOptionProperty();

        this.getUserLocations();

        if (this.$route.params.id || false) {
            this.loading = true;
            this.getAccountEmployerById(this.$route.params.id).then(data => {
                this.handleEmployerTypes = data.employment_types.filter(item => item != null) || [];
                this.handleEmployersIndustries = data.industries.filter(item => item != null) || [];
            }).finally(() => this.loading = false);
        }


    },

    methods: {
        ...mapActions("optionProperty", [
            "getOptionProperty",
            "getIslandsByAtollName",
        ]),
        ...mapActions("employer", [
            "getAccountEmployerById",
            "createEmployer",
            "updateEmployer",
            "onFileChanged",
            "updateEmployerInput",
            "updateEmployerMultipleSelect",
            "updateEmployerDescriptionInput",
            "clearOldIslandValue",
            "clearEmployerProfileForm",
            "getUserLocations",
        ]),

        action() {
            this.isEdit ? this.update() : this.create();
        },

        create() {
            this.createEmployer(this.employer)
                .then(() => {
                    this.$router.push({ name: "account-employer" });
                    this.successToast();
                })
                .catch((data) => {
                    this.errotToast(data.response.data.message ?? 'Something went wrong');
                });
        },

        update() {
            this.updateEmployer(this.employer)
                .then(() => {
                    this.$router.push({ name: "account-employer" });
                    this.successToast();
                })
                .catch((data) => {
                    this.errotToast(data.response.data.message ?? 'Something went wrong');
                });
        },

        handleAtollIslandRelation(event) {
            this.clearOldIslandValue();
            this.getIslandsByAtollName(event.target.value);
            this.updateEmployerInput(event);
        },

        // mountEmployerDescriptionInput(event) {
        //     this.updateEmployerDescriptionInput(event);
        // }


    },
};
</script>