<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/announcements',
                    name: 'Announcements',
                },
                {
                    route: '/account/announcements',
                    name: 'Create Announcements',
                },
            ]"
        />
        <announcement-form :isEdit="false" />
    </div>
</template>

<script>
    import AnnouncementForm from "./components/AnnouncementForm.vue";

    export default {
        components: {
            AnnouncementForm,
        },
    };
</script>