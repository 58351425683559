<template>
    <div class="blogpost style2 min-w-100 bg-white">
        <div class="blog-posthumb">
            <a href="#" title="">
                <img :src="award.file?.path || '#'" alt="" />
            </a>
        </div>
        <div v-if="isAccount || false" class="float-right">
            <router-link
                :to="{
                    name: 'account-award-edit',
                    params: { id: award.id },
                }"
                ><div class="color-primary"><i class="fas fa-edit"></i></div
            ></router-link>
            <div @click="destroy(award.id)" class="text-danger">
                <i class="fas fa-trash"></i>
            </div>
        </div>
        <div class="blog-postdetail ml-3">
            <ul class="post-metas">
                <li>
                    <a href="#" title=""
                        ><i class="la la-calendar-o"></i
                        >{{ award.date_formated }}</a
                    >
                </li>
            </ul>
            <h3>
                <a class="google-award" href="#" title="">{{ award.title }}</a>
                <p>{{ award.description }}</p>
            </h3>
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["award", "isAccount"],

        methods: {
            ...mapActions("award", ["getAccountAwards", "destroyAward"]),

            destroy(id) {
                this.destroyAward(id).then(() => {
                    this.getAccountAwards();
                    this.successToast();
                }).catch(() => this.errorToast());
            },
        },
    };
</script>