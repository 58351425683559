<template>
    <div class="">
        <div class="card-body">
            <h5 class="card-title">Create Locations</h5>
            <br />
            <div class="card card-plain">
                <form @submit.prevent="action">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>Address</label>
                            <input type="text" class="form-control" placeholder="Type Address" name="address"
                                v-model="form.address" />
                        </div>
                        <div class="form-group col-md-6">
                            <label>City/Atoll</label>
                            <select class="form-control" name="atoll" @input="handleAtollIslandRelation">
                                <option disabled>Please Select Atoll</option>
                                <option v-for="option in options.atoll" :key="option.id" :value="option.name"
                                    :title="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Island/Ward</label>
                            <select class="form-control" name="island" v-model="form.address_island_id">
                                <option disabled>Please Select Island</option>
                                <option v-if="(options.island || []).length == 0" disabled>Loading...</option>
                                <option v-for="option in options.island || []" :key="option.id" :value="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-common log-btn">Save</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

    data: () => ({
        form: {
            addess: '',
            address_atoll_id: '',
            address_island_id: '',
        }
    }),

    computed: {
        ...mapState("optionProperty", ["options"]),
    },

    mounted() {
        this.getOptionProperty();
    },

    methods: {
        ...mapActions("optionProperty", [
            "getOptionProperty",
            "getIslandsByAtollName",
        ]),
        ...mapActions("employer", [
            "clearOldIslandValue",
            "createUserLocation",
            "getUserLocations",
        ]),

        action() {
            this.createUserLocation(this.form)
                .then(() => {
                    this.getUserLocations();
                    this.successToast();
                })
                .catch(() => {
                    this.errorToasT();
                }).finally(() => this.hideLocationFormPopup());
        },

        handleAtollIslandRelation(event) {
            this.clearOldIslandValue();
            this.getIslandsByAtollName(event.target.value);
            this.form.address_atoll_id = event.target.options[event.target.selectedIndex].title;
        },

    },
};
</script>