import AccountTeamRoles from './../../views/account/team-role/Index.vue';
import CreateTeamRole from './../../views/account/team-role/Create.vue';
import EditTeamRole from './../../views/account/team-role/Edit.vue';
import TeamMembers from './../../views/account/team-member/Index.vue';

const routes = [
    {
        path: '/account/team-roles',
        name: 'account-team-roles',
        meta: {
            layout: 'account',
            title: 'Team Roles - Account',
        },
        component: AccountTeamRoles
    },
    {
        path: '/account/team-roles/create',
        name: 'account-team-role-create',
        meta: {
            layout: 'account',
            title: 'Create Team Role - Account',
        },
        component: CreateTeamRole
    },
    {
        path: '/account/team-roles/:id/edit',
        name: 'account-team-role-edit',
        meta: {
            layout: 'account',
            title: 'Edit Team Role - Account',
        },
        component: EditTeamRole
    },
    {
        path: '/account/team-members',
        name: 'account-team-members',
        meta: {
            layout: 'account',
            title: 'Team Members - Account',
        },
        component: TeamMembers
    },
];

export default routes;