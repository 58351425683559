import AccountOpportunities from './../../views/account/opportunity/Index.vue';
import CreateOpportunity from './../../views/account/opportunity/Create.vue';
import EditOpportunity from './../../views/account/opportunity/Edit.vue';
import OpportunityTemplates from './../../views/account/opportunity/job-templates/Index.vue';

const routes = [
    {
        path: '/account/opportunities',
        name: 'account-opportunity',
        meta: {
            layout: 'account',
            title: 'Opportunities - Account',
        },
        component: AccountOpportunities
    },
    {
        path: '/account/opportunities/create/:id?/:template?',
        name: 'account-opportunity-create',
        meta: {
            layout: 'account',
            title: 'Create Opportunity - Account',
        },
        component: CreateOpportunity
    },
    {
        path: '/account/opportunities/:id/edit',
        name: 'account-opportunity-edit',
        meta: {
            layout: 'account',
            title: 'Edit Opportunity - Account',
        },
        component: EditOpportunity
    },
    {
        path: '/account/opportunities/templates',
        name: 'account-opportunity-templates',
        meta: {
            layout: 'account',
            title: 'Templates Opportunity - Account',
        },
        component: OpportunityTemplates
    },
];

export default routes;