import Photo from "../../../../services/apis/photo";

const state = () => ({
    employerId: null,
    previewfile: null,
    photos: [],
    arrangePhotos: [],
    arrangePhotosText: 'Please Select Employer Profile',
    photo: {
        id: "",
        user_id: "",
        employer_id: "",
        file_id: "",
        caption: "",
        status: "",
        file: null,
    },
});

const actions = {
    initAccountData: ({ dispatch, commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        dispatch("getAccountPhotos");
    },

    createPhoto: ({ commit }, photo) => {
        Photo.store(photo).then(() => {
            commit("CLEAR_PHOTO");
        });
    },

    getAccountPhotos: ({ commit, state }) => {
        Photo.accounts().then((response) => {
            commit("SET_PHOTOS", response.data);
            if (state.employerId != null) {
                commit('GET_PHOTOS_BY_EMPLOYER_ID', state.employerId);
            }
        });
    },

    getPhotosByEmployerId: ({ commit }, empId) => {
        commit('SET_EMPLOYER_ID', empId);
        commit('GET_PHOTOS_BY_EMPLOYER_ID', empId);
    },

    getPhotoById: ({ commit }, id) => {
        Photo.find(id).then((response) => {
            commit("SET_PHOTO", response.data);
        });
    },

    updatePhoto: ({ commit }, photo) => {
        Photo.update(photo).then(() => {
            commit("CLEAR_PHOTO");
        });
    },

    destroyPhoto: (_, id) => {
        const txt = "Are you sure, you want to delete this photo?";
        if (confirm(txt)) {
            Photo.destroy(id);
        }
    },

    onFileChanged: ({ commit }, event) => {
        commit("SET_SELECTED_FILE", event);
    },

    updatePhotoInput({ commit }, event) {
        commit('UPDATE_PHOTO_INPUT', event);
    },

    clearPhotoForm: ({ commit }, empId) => {
        commit("CLEAR_PHOTO", empId);
    }
};

const getters = {};

const mutations = {
    SET_PHOTO: (state, photo) => {
        state.photo = photo;
        state.previewfile = state.photo.file;
        state.photo.file = null;
    },

    SET_PHOTOS: (state, photos) => {
        state.photos = photos;
    },

    SET_SELECTED_FILE: (state, event) => {
        state.photo.file = event.target.files[0];
    },

    CLEAR_PHOTO: (state, empId) => {
        state.photo = {
            id: '',
            user_id: '',
            employer_id: empId || '',
            file_id: '',
            title: '',
            description: '',
            date: '',
            status: '',
            file: null,
        };
        state.previewfile = null;
        state.arrangePhotos = [];
    },

    UPDATE_PHOTO_INPUT: (state, event) => {
        let temp = state.photo;
        temp[event.target.name] = event.target.value;
        state.photo = temp;
    },
    
    GET_PHOTOS_BY_EMPLOYER_ID: (state, employerId) => {
        state.arrangePhotos = [];
        state.arrangePhotos = state.photos.filter(item => item.employer_id == employerId);
        state.arrangePhotosText = state.arrangePhotos.length ? '' : '0 Photo Found!';
    },

    SET_EMPLOYER_ID: (state, id) => {
        state.employerId = id;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
