<template>
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="EducationalCertificates"
                    name="additional.educational_certificates"
                    :value="opportunity.additional?.educational_certificates ?? ''"
                    :checked="opportunity.additional?.educational_certificates ?? false" @change="handleOpportunityInput" />
                <label class="form-check-label" for="EducationalCertificates">Educational
                    Certificates</label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="EmploymentCertificates"
                    name="additional.employment_certificates" :value="opportunity.additional?.employment_certificates ?? ''"
                    :checked="opportunity.additional?.employment_certificates ?? false" @change="handleOpportunityInput" />
                <label class="form-check-label" for="EmploymentCertificates">Employment
                    Certificates</label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="ReferenceLetter" name="additional.reference_letter"
                    :value="opportunity.additional?.reference_letter ?? ''"
                    :checked="opportunity.additional?.reference_letter ?? false" @change="handleOpportunityInput" />
                <label class="form-check-label" for="ReferenceLetter">Reference Letter</label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="IdentityCard" name="additional.identity_card"
                    :value="opportunity.additional?.identity_card ?? ''"
                    :checked="opportunity.additional?.identity_card ?? false" @change="handleOpportunityInput" />
                <label class="form-check-label" for="IdentityCard">Identity Card</label>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="Passport" name="additional.passport"
                    :value="opportunity.additional?.passport ?? ''" :checked="opportunity.additional?.passport ?? false"
                    @change="handleOpportunityInput" />
                <label class="form-check-label" for="Passport">Passport</label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="PoliceReport" name="additional.police_report"
                    :value="opportunity.additional?.police_report ?? ''"
                    :checked="opportunity.additional?.police_report ?? false" @change="handleOpportunityInput" />
                <label class="form-check-label" for="PoliceReport">Police Report</label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="Photograph" name="additional.photograph"
                    :value="opportunity.additional?.photograph ?? ''" :checked="opportunity.additional?.photograph ?? false"
                    @change="handleOpportunityInput" />
                <label class="form-check-label" for="Photograph">Photograph</label>
            </div>
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="CV" name="additional.cv"
                    :value="opportunity.additional?.cv ?? ''" :checked="opportunity.additional?.cv ?? false"
                    @change="handleOpportunityInput" />
                <label class="form-check-label" for="CV">CV</label>
            </div>
        </div>
    </div>
</template>


<script>
import OpportunityMixin from '@/mixins/OpportunityMixin.js';

export default {
    props: {
        isEdit: Boolean,
    },

    mixins: [OpportunityMixin],

};
</script>