import Api from "./api.js";

const END_POINT = '/certificates';
const ACCOUNT_END_POINT = '/account' + END_POINT;

export default {

    all() {
        return Api.get(END_POINT);
    },

    accounts() {
        return Api.get(ACCOUNT_END_POINT);
    },

    store(data) {
        return Api.post(ACCOUNT_END_POINT, data);
    },

    find(id) {
        return Api.get(ACCOUNT_END_POINT + `/${id}`);
    },

    update(data) {
        return Api.put(ACCOUNT_END_POINT + `/${data.id}`, data);
    },

    destroy(id) {
        return Api.delete(ACCOUNT_END_POINT + `/${id}`);
    },

}