<template>
    <div class="contact-block">
        <h2>Leave us a Message</h2>
        <form @submit.prevent="submit">
            <div class="row">
                <div class="col-md-12">
                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            name="name"
                            placeholder="Full Name"
                            required
                            data-error="Please enter your full name"
                            v-model="form.name"
                            maxlength="254"
                        />
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input
                            type="email"
                            placeholder="Email"
                            id="email"
                            class="form-control"
                            name="name"
                            required
                            data-error="Please enter your email"
                            v-model="form.email"
                            maxlength="254"
                        />
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input
                            type="text"
                            placeholder="Mobile Number"
                            id="msg_subject"
                            class="form-control"
                            required
                            data-error="Please enter your Mobile Number"
                            v-model="form.phone"
                            maxlength="254"
                        />
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input
                            type="text"
                            placeholder="Country"
                            id="msg_country"
                            class="form-control"
                            required
                            data-error="Please enter your Country"
                            v-model="form.country"
                            maxlength="254"
                        />
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <input
                            type="text"
                            placeholder="Subject"
                            id="msg_subject"
                            class="form-control"
                            required
                            data-error="Please enter your Subject"
                            v-model="form.subject"
                            maxlength="254"
                        />
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea
                            class="form-control"
                            id="message"
                            placeholder="Your Message"
                            rows="5"
                            data-error="Write your message"
                            required
                            v-model="form.message"
                            maxlength="499"
                        ></textarea>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="submit-button">
                        <button class="btn btn-common" id="submit" type="submit">Send Message</button>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "contact-form",

        data: () => {
            return {
                form: {
                    name: "",
                    email: "",
                    phone: "",
                    country: "",
                    subject: "",
                    message: "",
                },
            };
        },

        methods: {
            ...mapActions("contact", ["contact"]),

            submit() {
                this.contact(this.form)
                    .then(() => {
                        this.$toast.success(`Success! Your information has been submitted.`, {
                            position: "top-right",
                        });
                        this.clearForm();
                    })
                    .catch(() => {
                        this.$toast.error(`Something went wrong`, {
                            position: "top-right",
                        });
                        this.clearForm();
                    });
            },

            clearForm() {
                this.form = {
                    name: "",
                    email: "",
                    phone: "",
                    country: "",
                    subject: "",
                    message: "",
                };
            },
        },
    };
</script>