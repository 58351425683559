import Appearance from "../../../services/apis/appearance";
import Category from "../../../services/apis/category";

const state = () => ({
    heroSection: {},
    clientWhoTrust: [],
    howItWork: {},
    whyChooseUs: {},
    appDownload: {},
    appContactInfo: {},
    popularCategories: [],
});

const actions = {

    getHeroSection: ({ commit }) => {
        Appearance.heroSection().then((response) => {
            commit("SET_HERO_SECTION", response.data);
        });
    },

    getClientWhoTrustSection: ({ commit }) => {
        Appearance.clientWhoTrustSection().then((response) => {
            commit("SET_CLIENT_WHO_TRUST_SECTION", response.data);
        });
    },

    getHowItWorkSection: ({ commit }) => {
        Appearance.howItWorkSection().then((response) => {
            commit("SET_HOW_IT_WORK_SECTION", response.data);
        });
    },

    getWhyChooseUsSection: ({ commit }) => {
        Appearance.whyChooseUsSection().then((response) => {
            commit("SET_WHY_CHOOSE_US_SECTION", response.data);
        });
    },

    getAppDownloadSection: ({ commit }) => {
        Appearance.appDownlaodSection().then((response) => {
            commit("SET_APP_DOWNLOAD_SECTION", response.data);
        });
    },

    getAppContactInfo: ({ commit }) => {
        Appearance.appContactInfo().then((response) => {
            commit("SET_APP_CONTACT_INFO", response.data);
        });
    },

    getPopularCategories: ({ commit }) => {
        Category.popular().then(response => {
            commit("SET_POPULAR_CATEGORIES", response.data);
        });
    }
};

const getters = {};

const mutations = {
    SET_HERO_SECTION: (state, data) => {
        state.heroSection = data;
    },

    SET_CLIENT_WHO_TRUST_SECTION: (state, data) => {
        state.clientWhoTrust = data;
    },

    SET_HOW_IT_WORK_SECTION: (state, data) => {
        state.howItWork = data;
    },

    SET_WHY_CHOOSE_US_SECTION: (state, data) => {
        state.whyChooseUs = data;
    },

    SET_APP_DOWNLOAD_SECTION: (state, data) => {
        state.appDownload = data;
    },

    SET_APP_CONTACT_INFO: (state, data) => {
        state.appContactInfo = data;
    },

    SET_POPULAR_CATEGORIES: (state, data) => {
        state.popularCategories = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};

