import Api from "./api.js";

const ACCESS_TOKEN_KEY = 'token';
const USER_KEY = 'user';
const END_POINT = '/auth';
const LOGIN_END_POINT = END_POINT + '/login';
const REGISTER_END_POINT = END_POINT + '/register';
// const LOGOUT_END_POINT = END_POINT + '/logout';

export default {

    tokenlogin() {
        return Api.get('/auth/custom-token')
    },

    login(user) {
        return Api.post(LOGIN_END_POINT, user);
    },

    register(user) {
        return Api.post(REGISTER_END_POINT, user);
    },

    logout() {
        this.removeToken();
        this.removeUser();
    },

    getToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY);
    },

    setToken(token) {
        this.setAuthHeader(token);
        return localStorage.setItem(ACCESS_TOKEN_KEY, token);
    },

    hasToken() {
        return !!localStorage.getItem(ACCESS_TOKEN_KEY);
    },

    removeToken() {
        return localStorage.removeItem(ACCESS_TOKEN_KEY);
    },

    setUser(user) {
        return localStorage.setItem(USER_KEY, user);
    },

    removeUser() {
        return localStorage.removeItem(USER_KEY);
    },

    setAuthHeader(token) {
        Api.defaults.headers['X-Authorization'] = `Bearer ${token}`;
        Api.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
};