import axios from "axios";

const Api = axios.create({
    baseURL: 'https://admin.jobbank.mv/api',
    headers: {
        "X-Authorization": `Bearer ${localStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
});

export default Api;