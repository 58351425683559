<template>
    <div>
        <!--top bannner-->
        <div class="container opportunities-top pb-5 m-opportunites">
            <div class="row space-100">
                <div class="container mt-5">
                    <recent-opportuity-header />

                    <!-- search form -->
                    <opportunity-search-from :hasJobType="false" />
                </div>
            </div>
        </div>
        <!--top bannner-->
        <section id="featured" class="section bg-cyan">
            <div class="container">
                <div class="row">
                    <div class="opportunities-top">
                        <h3>{{ totalOpportunities || 0 }} Opportunities From {{ totalEmployers || 0 }} Employers</h3>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-2 m-footer">
                        <div class="btn-group">
                            <button class="active listview"><i class="fa fa-bars"></i> List</button>
                            <button class="gridview"><i class="fa fa-th-large"></i> Grid</button>
                        </div>
                    </div>

                    <div class="col-md-3 m-sort-by">
                        <div class="sortby-sec mobile-filter-select">
                            <div class="float-left mr-3 mt-1 m-footer">
                                <p>Sort by</p>
                            </div>
                            <div class="float-left">
                                <select class="opportunities-select">
                                    <option>Sort by</option>
                                    <option>Most Recent</option>
                                    <option>Apply Deadline</option>
                                    <option>Top Jobs</option>
                                    <option>Top Employers</option>
                                    <option>Top Salary</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 m-sort-by">
                        <div class="alert-btn">
                            <button onclick="addstyle()" class="btn filterToogle">
                                <i class="fas fa-sliders-h"></i>Show Filter
                            </button>
                            <button class="btn m-footer"><i class="fa fa-envelope"></i> Get Job Alerts</button>
                        </div>
                    </div>
                </div>

                <!--  job section-->
                <div class="row mt-3">
                    <!-- filter section -->

                    <div id="filter" class="col-md-4">
                        <div class="col-md-12">
                            <div class="card p-4">
                                <div class="close-button">
                                    <button type="button" class="close" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="widget">
                                    <div class="search_widget_job">
                                        <div class="field_w_search mt-3">
                                            <input type="text" placeholder="Job title or Skills" class="form-control" />
                                        </div>
                                        <div class="field_w_search mt-3">
                                            <input type="text" placeholder="Atoll/City or Island/Ward"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="widget">
                                    <!--date posted-->
                                    <div class="sidebar-widget">
                                        <h5 class="sb-title">Date Posted</h5>
                                        <div class="posted_widget">
                                            <div class="simple-checkbox">
                                                <div class="range_slider">
                                                    <div class="nstSlider" data-range_min="0" data-range_max="30"
                                                        data-cur_min="0" enable data-cur_max="30">
                                                        <div class="bar"></div>
                                                        <div class="leftGrip"></div>
                                                        <div class="rightGrip"></div>
                                                    </div>
                                                    <div class="leftLabel"></div>
                                                    <div class="rightLabel float-right"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Application Deadline-->
                                    <div class="sidebar-widget">
                                        <h5 class="sb-title">Application Deadline</h5>
                                        <div class="posted_widget">
                                            <div class="simple-checkbox">
                                                <div class="range_slider">
                                                    <div class="nstSlider" data-range_min="1" data-range_max="15"
                                                        data-cur_min="0" data-cur_max="15">
                                                        <div class="bar"></div>
                                                        <div class="leftGrip"></div>
                                                        <div class="rightGrip"></div>
                                                    </div>
                                                    <div class="leftLabel"></div>
                                                    <div class="rightLabel float-right"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Employment Sector-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Employment Sector</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Government</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Private</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Public</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>NGO</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Industry-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Industry</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Tourism</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Fisheries</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Information Technology</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Others</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Field of Work-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Field of Work</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Food &
                                                    Beverage</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Housekeeping</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Others</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!--  Employment  Type-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Employment Type</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>
                                                    Freelance</label>
                                            </div>

                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span> Full
                                                    Time</label>
                                            </div>

                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span> Part
                                                    Time</label>
                                            </div>

                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>
                                                    Internship</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>
                                                    Temporary</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!--  Workplace-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Workplace</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Work
                                                    from Home</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Work
                                                    online</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Work
                                                    Abroad</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Work
                                                    Onsite</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Salary Scale-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Salary Scale</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>USD</label>
                                            </div>

                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>MVR</label>
                                            </div>
                                            <div class="range_slider">
                                                <div class="nstSlider" data-range_min="0" data-range_max="10000"
                                                    data-cur_min="0" data-cur_max="10000">
                                                    <div class="bar"></div>
                                                    <div class="leftGrip"></div>
                                                    <div class="rightGrip"></div>
                                                </div>
                                                <div class="leftLabel"></div>
                                                <div class="rightLabel"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Career Level-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Career Level</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Entry
                                                    level</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Intermediate</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Supervisory</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Others</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Education level-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Education level</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Basic
                                                    Education</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>O-Level</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Licenses & Certifications-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Licenses & Certifications</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Company
                                                    License</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Driving
                                                    License</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Tax
                                                    Certificate</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Gender-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Gender</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Male</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Female</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span> NO
                                                    Preference</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Nationality Required -->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Nationality Required</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Locals</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Foreign</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span> NO
                                                    Preference</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Work Shift  -->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Work Shift</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Morning</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Afternoon</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Night</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Any</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Pay Period  -->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Pay Period</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Per
                                                    Hour</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Per
                                                    Day</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Per
                                                    Week</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Per
                                                    Month</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Per
                                                    Year</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Ownership Types  -->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Ownership Types</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Private
                                                    Company</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Public
                                                    Company</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Sole
                                                    Proprietorship</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Experience-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Experience</h3>
                                        <div class="posted_widget">
                                            <div class="simple-checkbox">
                                                <div class="range_slider">
                                                    <div class="nstSlider" data-range_min="1" data-range_max="15"
                                                        data-cur_min="0" data-cur_max="15">
                                                        <div class="bar"></div>
                                                        <div class="leftGrip"></div>
                                                        <div class="rightGrip"></div>
                                                    </div>
                                                    <div class="leftLabel"></div>
                                                    <div class="rightLabel float-right"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Volunteer Experience  -->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Volunteer Experience</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Volunteer Experience</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- language-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Languages</h3>
                                        <div class="switches-list">
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>English</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>French</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Russian</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Germany</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Spanish</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Italian</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Chinese</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Japanese</label>
                                            </div>
                                            <div class="switch-container">
                                                <label class="switch"><input type="checkbox" /><span
                                                        class="switch-button"></span>Arabic</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Benefits Offered-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Benefits Offered</h3>
                                        <div class="">
                                            <div class="switches-list">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" /><span
                                                            class="switch-button"></span>Health Insurance</label>
                                                </div>
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" /><span
                                                            class="switch-button"></span>Retirement plan</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- CV Less Jobs-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">CV Less Jobs</h3>
                                        <div class="">
                                            <div class="switches-list">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" /><span
                                                            class="switch-button"></span>CV
                                                        Less Jobs</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- skill/ tags-->
                                    <div class="sidebar-widget">
                                        <h3 class="sb-title">Skills/TAGS</h3>
                                        <div class="">
                                            <div class="switches-list">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" /><span
                                                            class="switch-button"></span>MS
                                                        Office</label>
                                                </div>
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" /><span
                                                            class="switch-button"></span>PHP</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="filter-gridview" class="col-md-8">
                        <div class="row">
                            <opportunity-grid-view-card v-for="opportunity in opportunities.data" :key="opportunity.id"
                                :opportunity="opportunity" />
                        </div>
                    </div>

                    <div id="filter-listview" class="col-md-12">
                        <opportunity-list-view-card v-for="opportunity in opportunities.data" :key="opportunity.id"
                            :opportunity="opportunity" :isAccount="false" />
                    </div>
                </div>

                <v-pagination :total-page="Math.ceil(opportunities.total / opportunities.per_page)"
                    :current-page="opportunities.current_page" @page-changed="getOpportunities">
                </v-pagination>
            </div>
        </section>
        <!--app section-->
        <app-download />
        <!--app section-->
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OpportunityListViewCard from "@/components/opportunities/OpportunityListViewCard.vue";
import OpportunityGridViewCard from "@/components/opportunities/OpportunityGridViewCard.vue";
import VPagination from "@/components/utils/VPagination.vue";
import AppMixin from "@/mixins/AppMixin.js";
import AppDownload from "./../home/components/AppDownload.vue";
import RecentOpportuityHeader from "./components/RecentOpportuityHeader.vue";
import OpportunitySearchFrom from "./components/OpportunitySearchForm.vue";

export default {
    name: "opportunities",

    components: {
        OpportunityListViewCard,
        OpportunityGridViewCard,
        VPagination,
        AppDownload,
        RecentOpportuityHeader,
        OpportunitySearchFrom
    },

    mixins: [AppMixin],

    computed: {
        ...mapState("opportunity", ["opportunities"]),
        ...mapState("employer", [
            "totalEmployers",
            "totalOpportunities",
        ]),
    },

    mounted() {
        if ((this.opportunities.data || []).length == 0) {
            const category = this.$route.query.categoryId || null;
            if (category != null) {
                this.searchOpportunities({ category_id: category });
            } else {
                this.getOpportunities();
            }
        }
        this.countEmployers();
    },

    methods: {
        ...mapActions("opportunity", ["getOpportunities"]),
        ...mapActions("employer", ["countEmployers"]),
    },
};
</script>