<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/employer',
                    name: 'Employers',
                },
                {
                    route: '/account/employer',
                    name: 'Create Employers',
                },
            ]"
        />
        <employer-form />
    </div>
</template>


<script>
    import EmployerForm from "./components/EmployerForm.vue";

    export default {
        components: {
            EmployerForm,
        },
    };
</script>