<template>
    <div>
        <app-top-banner title="Packages" />

        <section id="featured" class="section bg-cyan">
            <div class="container">
                <div class="row">
                    <div class="opportunities-top">
                        <h3>CHOOSE YOUR IDEAL <strong>PACKAGE</strong></h3>
                    </div>
                </div>
            </div>

            <div class="pricing">
                <div class="block">
                    <div class="container">
                        <div class="row">
                            <div class="pricetable">
                                <div class="tab-content">
                                    <div class="col-12 tab-pane active">
                                        <div class="row price-table">
                                            <table class="table border col-sm-12 text-center">
                                                <thead class="table-header col-md-12">
                                                    <th>Package Elements</th>
                                                    <th v-for="item in packages.package || []" :key="item.id">
                                                        {{ item.title || "" }} <br />
                                                        ${{ item.price || "" }}
                                                    </th>
                                                </thead>
                                                <tbody class="table-body">
                                                    <tr
                                                        v-for="element in packages.package_elements || []"
                                                        :key="element.id"
                                                    >
                                                        <td>{{ element.title || "" }}</td>
                                                         <td v-for="item in packages.package || []" :key="item.id">
                                                            <div v-if="item.elements?.length > 0">
                                                                {{
                                                                    item.elements.filter(
                                                                        (el) => (el.item.id || 0) == element.id
                                                                    ).length > 0 ? item.elements.filter(
                                                                        (el) => (el.item.id || 0) == element.id
                                                                    )[0].value || 'X' : 'X'
                                                                }}
                                                            </div>
                                                            <div v-else>
                                                                X
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td v-for="item in packages.package || []" :key="item.id">
                                                            <input
                                                                class="btn btn-common"
                                                                type="button"
                                                                value="Buy Now"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import AppTopBanner from "@/components/utils/AppTopBanner.vue";
    import { mapState, mapActions } from "vuex";

    export default {
        name: "pricing",

        components: {
            AppTopBanner,
        },

        computed: {
            ...mapState("packages", ["packages"])
        },

        mounted() {
            this.getPackages();
        },

        methods: {
            ...mapActions("packages", ["getPackages"])
        }
    };
</script>

<style scoped>
    .pricetable td,
    .pricetable th {
        padding: 10px 20px;
    }
    .pricetable tr td:first-child,
    .pricetable tr th:first-child {
        text-align: start !important;
    }
    .pricetable .price-menu li {
        border: 2px solid #8db750;
        border-radius: 10px;
        font-size: 14px;
    }
    .pricetable .price-menu li:hover {
        background: #8db750;
        color: #ffffff;
    }
    .pricetable .price-table table {
        border-collapse: collapse;
        border-radius: 1em;
        overflow: hidden;
        width: 100%;
        margin-top: 10px;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .pricetable .price-table .table-header {
        background: #8db750;
        color: #ffffff;
        border-radius: 10px;
    }
    .pricetable .price-table .table-body tr td i {
        font-weight: bold;
    }
    .table-bottom {
        margin-left: 33%;
        text-align: left;
        color: #999999;
        font-size: 14px;
    }
    .table-bottom span,
    .price-left span {
        display: block;
    }
    .price-top {
        text-align: left;
    }
    .price-left span i {
        font-size: 18px;
        color: #8db750;
    }
    .price-left {
        text-align: center;
        font-size: 14px;
    }
</style>