<template>
    <div class="row mt-4">
        <div v-if="selectedEmployerId == null" class="col-md-6">
            <div class="form-group">
                <label>Select Employer Profile</label>
                <select class="form-control" name="employer_id" :value="opportunity.employer_id"
                    @input="handleOpportunityInput" :disabled="isEdit || false">
                    <option v-for="option in accounts" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Job Title</label>
                <v-select label="title" v-model="handleJobTitle" :options="options.job_title || []"
                    :reduce="(option) => option.title" class="form-control"></v-select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Job Role</label>
                <input type="text" class="form-control" placeholder="Job Role" name="role" :value="opportunity.role"
                    @input="handleOpportunityInput" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Job Alias</label>
                <input type="text" class="form-control" placeholder="Job Alias" name="alias" :value="opportunity.alias"
                    @input="handleOpportunityInput" />
            </div>
        </div>
        <div class="col-md-12">
            <div class="mb-4">
                <label>Description</label>
                <ckeditor :editor="editor" :model-value="opportunity.description?.description ?? ''"
                    :value="opportunity.description?.description ?? ''" @input="updateOpportunityDescriptionInput">
                </ckeditor>
            </div>
        </div>
    </div>
</template>


<script>
import OpportunityMixin from '@/mixins/OpportunityMixin.js';
import vSelect from 'vue-select';
import CKEditor from "@ckeditor/ckeditor5-vue";

export default {
    props: {
        isEdit: Boolean,
    },

    components: {
        vSelect,
        ckeditor: CKEditor.component,
    },

    mixins: [OpportunityMixin],

};
</script>