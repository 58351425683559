import AccountCertificates from './../../views/account/certificate/Index.vue';
import CreateCertificate from './../../views/account/certificate/Create.vue';
import EditCertificate from './../../views/account/certificate/Edit.vue';

const routes = [
    {
        path: '/account/certificates',
        name: 'account-certificate',
        meta: {
            layout: 'account',
            title: 'Certificates - Account',
        },
        component: AccountCertificates
    },
    {
        path: '/account/certificates/create',
        name: 'account-certificate-create',
        meta: {
            layout: 'account',
            title: 'Create Certificate - Account',
        },
        component: CreateCertificate
    },
    {
        path: '/account/certificates/:id/edit',
        name: 'account-certificate-edit',
        meta: {
            layout: 'account',
            title: 'Edit Certificate - Account',
        },
        component: EditCertificate
    },
];

export default routes;