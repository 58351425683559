import Api from "./api.js";

const PACKAGES_END_POINT = '/packages';

export default {

    getPackages() {
        return Api.get(PACKAGES_END_POINT);
    },

};