import Api from "./api.js";

const END_POINT = '/contact';

export default {

    store(data) {
        return Api.post(END_POINT, data);
    },

}