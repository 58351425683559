<template>
    <div class="blogpost style2 bg-white">
        <div class="float-right">
            <router-link
                :to="{
                    name: 'account-certificate-edit',
                    params: { id: certificate.id },
                }"
                ><div class="color-primary"><i class="fas fa-edit"></i></div
            ></router-link>
            <div @click="destroy(certificate.id)" class="text-danger">
                <i class="fas fa-trash"></i>
            </div>
        </div>
        <div class="blog-postdetail mt-4">
            <ul class="post-metas mb-2">
                <li>
                    <a href="#" title=""><i class="la la-calendar-o"></i>{{ certificate.issue_date }}</a>
                </li>
            </ul>
            <span>
                <a class="project-aanoun" href="#" title="">{{ certificate.name }}</a>
                <p>
                    {{ certificate.credential_id }}
                </p>
                <a class="bbutton" :href="'//' + certificate.credential_url || '#'" target="_blank">
                    <i class="fas fa-file-pdf"></i> View Certificate
                    <i class="fas fa-long-arrow-alt-right"></i>
                </a>
            </span>
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["certificate"],

        methods: {
            ...mapActions("certificate", [
                "getAccountCertificates",
                "destroyCertificate",
            ]),

            destroy(id) {
                this.destroyCertificate(id).then(() => {
                    this.getAccountCertificates();
                    this.successToast();
                }).catch(() => this.errorToast());
            },
        },
    };
</script>
