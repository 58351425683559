<template>
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="form-group">
                <label>Gender</label>
                <select class="form-control" name="gender" :value="opportunity.gender" @input="handleOpportunityInput">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="No Preference">No Preference</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Experience</label>
                <v-select label="title" v-model="handleExperience" :options="options.experience || []"
                    :reduce="(option) => option?.title || ''" class="form-control" multiple></v-select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Qualification</label>
                <v-select label="title" v-model="handleQualification" :options="options.qualification || []"
                    :reduce="(option) => option?.title || ''" class="form-control" multiple></v-select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Certification</label>
                <v-select label="title" v-model="handleCertification" :options="options.certification || []"
                    :reduce="(option) => option?.title || ''" class="form-control" multiple></v-select>
            </div>
        </div>
        <!-- age is not listed -->
        <div class="col-md-6">
            <div class="form-group">
                <label>Age: {{ opportunity.age }}</label>
                <Slider name="handle_age_slider" :value="opportunity.handle_age_slider"
                    @change="handleAgeOpportunityInput" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Nationality</label>
                <select class="form-control" name="info.nationality" :value="opportunity.info?.nationality || ''"
                    @input="handleOpportunityInput">
                    <option v-for="option in options.nationality" :key="option.id" :value="option.title">
                        {{ option.title }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Languages</label>
                <v-select label="title" v-model="handleLanguages" :options="options.language || []"
                    :reduce="(option) => option?.title || ''" class="form-control" multiple></v-select>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label>Skills</label>
                <v-select label="title" v-model="handleSkills" :options="options.skill || []"
                    :reduce="(option) => option?.title || ''" class="form-control" multiple></v-select>
                <div>
                    <button v-for="item in (options.skill || []).slice(0, 5)" :key="item.id" class="btn btn-outline"
                        @click="() => handleSkillsBtn(item?.title || '')">
                        + {{ item?.title || "" }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-12 mx-4">
            <div class="form-group">
                <input type="checkbox" class="form-check-input" id="VolunteerExperience"
                    name="additional.volunteer_experience" :value="opportunity.additional?.volunteer_experience ?? ''"
                    :checked="opportunity.additional?.volunteer_experience ?? false" @input="handleOpportunityInput" />
                <label class="form-check-label" for="VolunteerExperience">
                    Volunteer Experience
                </label>
            </div>
        </div>
    </div>
</template>


<script>
import OpportunityMixin from '@/mixins/OpportunityMixin.js';
import vSelect from 'vue-select';
import Slider from '@vueform/slider';

export default {
    props: {
        isEdit: Boolean,
    },

    components: {
        vSelect,
        Slider,
    },

    mixins: [OpportunityMixin],

};
</script>