<template>
    <div class="row">
        <div v-if="accounts.length ?? false" class="col-md-8 mt-3 card box">
            <h6>{{ isEdit || false ? "Edit" : "Create" }} Certificate</h6>
            <form @submit.prevent="action">
                <div v-if="!(isEdit || false)" class="form-group">
                    <label>Select Employer Profile</label>
                    <select
                        class="form-control"
                        name="employer_id"
                        :value="certificate.employer_id"
                        @input="handleCertificateInput"
                    >
                        <option v-for="option in accounts" :key="option.id" :value="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Certificate Name</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type Certificate Name"
                            name="name"
                            :value="certificate.name"
                            @input="handleCertificateInput"
                            required
                            autofocus
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label>Certificate Org</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type Organization Name"
                            name="organization"
                            :value="certificate.organization"
                            @input="handleCertificateInput"
                            required
                            autofocus
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label>Certificate Date</label>
                        <input
                            type="date"
                            class="form-control"
                            placeholder="Issue Date"
                            name="issue_date"
                            :value="certificate.issue_date"
                            @input="handleCertificateInput"
                            required
                            autofocus
                        />
                    </div>
                    <div class="form-group col-md-4">
                        <label>Certificate Credential Id</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type Credential Id"
                            name="credential_id"
                            :value="certificate.credential_id"
                            @input="handleCertificateInput"
                            autofocus
                        />
                    </div>
                    <div class="form-group col-md-4">
                        <label>Certificate Credential Url</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Type Credential URL"
                            name="credential_url"
                            :value="certificate.credential_url"
                            @input="handleCertificateInput"
                            autofocus
                        />
                    </div>
                </div>
                <button type="submit" class="btn btn-common log-btn">
                    {{ isEdit || false ? "Update" : "Save" }}
                </button>
            </form>
        </div>
        <div v-else class="col-md-12 mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import Empty from "@/components/utils/Empty.vue";

    export default {
        props: {
            isEdit: Boolean,
        },

        components: { Empty },

        computed: {
            ...mapState("certificate", ["certificate"]),
            ...mapState("employer", ["accounts", "selectedEmployer"]),
        },

        mounted() {
            this.clearCertificateForm(this.selectedEmployer?.id || '');
            if(this.accounts.length == 0) {
                this.getAccountEmployers();
            }
        },

        methods: {
            ...mapActions("certificate", [
                "createCertificate",
                "updateCertificate",
                "updateCertificateInput",
                "clearCertificateForm",
            ]),
            ...mapActions("employer", ["getAccountEmployers"]),

            action() {
                this.isEdit ? this.update() : this.create();
            },

            create() {
                this.createCertificate(this.certificate)
                    .then(() => {
                        this.$router.push({
                            name: "account-certificate",
                        });
                        this.successToast();
                    })
                    .catch((data) => {
                        this.errorToast();
                        console.log(data);
                    });
            },

            update() {
                this.updateCertificate(this.certificate)
                    .then(() => {
                        this.successToast();
                        this.$router.push({
                            name: "account-certificate",
                        });
                    })
                    .catch((data) => {
                        this.errorToast();
                        console.log(data);
                    });
            },

            handleCertificateInput(event) {
                this.updateCertificateInput(event);
            },
        },
    };
</script>