<template>
    <div v-if="accounts.length ?? false">
        <form @submit.prevent="action">
            <div class="row">
                <div class="col-lg-8 col-md-6 box mx-4">
                    <h6>{{ isEdit || false ? "Edit" : "Create" }} Photo</h6>
                    <div v-if="!(isEdit || false)" class="form-group">
                        <label>Select Employer Profile</label>
                        <select
                            class="form-control"
                            name="employer_id"
                            :value="photo.employer_id"
                            @input="handlePhotoInput"
                        >
                            <option v-for="option in accounts" :key="option.id" :value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <textarea
                            class="form-control"
                            placeholder="Caption"
                            name="caption"
                            :value="photo.caption"
                            @input="handlePhotoInput"
                            rows="4"
                        ></textarea>
                    </div>
                    <button type="submit" class="btn btn-common log-btn">
                        {{ isEdit || false ? "Update" : "Save" }}
                    </button>
                </div>
                <div class="col-lg-3 col-md-5">
                    <div v-if="previewfile" class="mb-4">
                        <p class="text-muted mb-2">Preview</p>
                        <a class="bbutton" :href="previewfile.path || '#'" target="_blank">
                            <i class="fas fa-file-pdf"></i> View File
                            <i class="fas fa-long-arrow-alt-right"></i>
                            <img :src="previewfile.path || ''" alt="" class="img-fluid" />
                        </a>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Photo</h6>
                            <div class="card card-plain">
                                <div class="card-body">
                                    <input type="file" class="form-control" @input="onFileChanged" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div v-else class="row mb-5 mt-3">
        <div class="col-md-12">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import Empty from "@/components/utils/Empty.vue";

    export default {
        props: {
            isEdit: Boolean,
        },

        components: { Empty },

        computed: {
            ...mapState("photo", ["photo", "previewfile"]),
            ...mapState("employer", ["accounts", "selectedEmployer"]),
        },

        mounted() {
            this.clearPhotoForm(this.selectedEmployer.id || '');
            if(this.accounts.length == 0) {
                this.getAccountEmployers();
            }
        },

        methods: {
            ...mapActions("photo", [
                "createPhoto",
                "updatePhoto",
                "onFileChanged",
                "updatePhotoInput",
                "clearPhotoForm",
            ]),
            ...mapActions("employer", ["getAccountEmployers"]),

            action() {
                this.isEdit ? this.update() : this.create();
            },

            create() {
                this.createPhoto(this.photo)
                    .then(() => {
                        this.$router.push({
                            name: "account-photo",
                        });
                        this.successToast();
                    })
                    .catch((data) => {
                        this.errotToast(data.response.data.message ?? 'Something went wrong');
                    });
            },

            update() {
                this.updatePhoto(this.photo)
                    .then(() => {
                        this.$router.push({
                            name: "account-photo",
                        });
                        this.successToast();
                    })
                    .catch((data) => {
                        this.errotToast(data.response.data.message ?? 'Something went wrong');
                    });
            },

            handlePhotoInput(event) {
                this.updatePhotoInput(event);
            },
        },
    };
</script>