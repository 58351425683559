<template>
    <div>
        <breadcrumb :items="[
            {
                route: '/account/opportunities',
                name: 'Opportunities',
            },
        ]" />
        <div v-if="selectedEmployer || false">
            <div class="row align-items-center">
                <div class="col-md-12 text-right">
                    <router-link v-if="(selectedEmployerId || false) && selectedEmployerId > 0"
                        :to="{ name: 'account-opportunity-create' }" class="btn btn-common btn-effect wow fadeInRight mt-3"
                        data-wow-delay="0.9s">
                        Create
                    </router-link>
                </div>
            </div>
            <div v-if="arrangeOpportunities.length || false" class="row mx-3 mb-5 mt-3">
                <div class="col-12">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#Unpublished">Unpublished</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#Published">Published</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#Expired">Expired</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#Templates">Templates</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-3">
                        <div id="Unpublished" class="container tab-pane active m-0">
                            <opportunity-list-view-card v-for="opportunity in arrangeOpportunities.filter(
                                (item) => !(item.template || false) && ((!(item.published || false) && !(item.is_expired || false)) || !item.has_due_date)
                            )" :key="opportunity.id" :opportunity="opportunity" :isAccount="true" />
                        </div>
                        <div id="Published" class="container tab-pane fade m-0">
                            <opportunity-list-view-card v-for="opportunity in arrangeOpportunities.filter(
                                (item) => (item.published || false) && !(item.template || false) && item.has_due_date && !(item.is_expired || false)
                            )" :key="opportunity.id" :opportunity="opportunity" :isAccount="true" />
                        </div>
                        <div id="Expired" class="container tab-pane fade m-0">
                            <opportunity-list-view-card v-for="opportunity in arrangeOpportunities.filter(
                                (item) => !(item.template || false) && (item.is_expired || false)
                            )" :key="opportunity.id" :opportunity="opportunity" :isAccount="true" />
                        </div>
                        <div id="Templates" class="container tab-pane fade m-0">
                            <opportunity-list-view-card
                                v-for="opportunity in arrangeOpportunities.filter((item) => item.template || false)"
                                :key="opportunity.id" :opportunity="opportunity" :isAccount="true" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row justify-content-center mb-5 mt-3">
                <empty :title="arrangeOpportunitiesText" />
            </div>
        </div>
        <div v-else class="row justify-content-center mb-5 mt-3">
            <empty title="Please create employer profile frist!" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Empty from "@/components/utils/Empty.vue";
import OpportunityListViewCard from "@/components/opportunities/OpportunityListViewCard.vue";

export default {
    components: {
        Empty,
        OpportunityListViewCard,
    },

    computed: {
        ...mapState("employer", ["selectedEmployer"]),
        ...mapState("opportunity", ["arrangeOpportunities", "arrangeOpportunitiesText", "selectedEmployerId"]),
    },

    mounted() {
        if (this.selectedEmployer || false) {
            this.initAccountData(this.selectedEmployer.id);
        }
    },

    methods: {
        ...mapActions("opportunity", [
            "initAccountData",
        ]),
    },
};
</script>