<template>
    <div class="extra-job-info">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#jobs">Posted Jobs</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#rating_details">Ratings</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#awards">Awards</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#photos">Photos</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#announcements">Announcements</a>
            </li>
        </ul>
        <div class="tab-content">
            <div id="jobs" class="container tab-pane active">
                <div class="mt-4">
                    <div class="filterbar-2">
                        <div class="sortby-sec sortby-sec-1 m-footer">
                            <span>Filter by</span>
                            <select data-placeholder="All Jobs" class="chosen">
                                <option>All Jobs</option>
                                <option>Open Jobs</option>
                                <option>Expired Jobs</option>
                            </select>
                            <span class="ml-4"> Sort By</span>
                            <select data-placeholder="Date Posted" class="chosen">
                                <option>Date Posted</option>
                                <option>Field of work</option>
                                <option>Job Title</option>
                                <option>Date Due</option>
                                <option>Salary</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="employer.opportunities?.length || 0" class="job-listings-sec no-border mt-4">
                        <opportunity-list-view-card
                            v-for="opportunity in employer.opportunities"
                            :key="opportunity.id"
                            :opportunity="opportunity"
                            :isAccount="false"
                        />
                    </div>
                    <div v-else class="row justify-content-center mt-3">
                        <empty title="No Opportunities Yet" />
                    </div>
                </div>
            </div>
            <div id="rating_details" class="tab-pane fade">
                <div class="employer-rating-sec">
                    <div class="employer-rating-top mt-4">
                        <h3 class="rating-heading">Hunt Rating Score</h3>
                        <div class="rating">
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star"></span>
                            <p><strong>4.1</strong> average based on <strong>254</strong> reviews.</p>
                        </div>

                        <div class="row">
                            <div class="side">
                                <div>5 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-5" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        85%
                                    </div>
                                </div>
                            </div>
                            <div class="side">
                                <div>4 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-4" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        80%
                                    </div>
                                </div>
                            </div>
                            <div class="side">
                                <div>3 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-3" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        20%
                                    </div>
                                </div>
                            </div>
                            <div class="side">
                                <div>2 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-2" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        10%
                                    </div>
                                </div>
                            </div>
                            <div class="side">
                                <div>1 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-1" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        15%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="employer-rating-bottom mt-3">
                        <h3>Evaluating Factors</h3>

                        <!-- Work Life Balance-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Culture</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="ml-5">4/5</span>
                            </div>
                        </div>
                        <!-- Management-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Training</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full checked"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- Culture-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Job Security</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- Job Satisfaction-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Management</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full checked"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- Colleagues-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Job Satisfaction</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full checked"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- Job Security-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Work Life Balance</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full checked"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- Support You get-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Career Progression</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full checked"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- Career Progression-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Interview & Hiring Process</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star-half-full checked"></span>
                                <span class="ml-5">4.5/5</span>
                            </div>
                        </div>
                        <!-- I’m proud to work here-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Environmental Consciousnes</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="ml-5">4/5</span>
                            </div>
                        </div>
                        <!-- Compensation Package-->
                        <div class="mt-2">
                            <div class="col-md-6 float-left">
                                <span class="">Compensation & Benefits Package</span>
                            </div>
                            <div class="col-md-6 float-left rating">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="ml-5">4/5</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="awards" class="tab-pane fade">
                <div v-if="employer.awards?.length || 0">
                    <award-card v-for="award in employer.awards" :key="award.id" :award="award" :isAccount="false" />
                </div>
                <div v-else class="row justify-content-center mt-3">
                    <empty title="No Awards Yet" />
                </div>
            </div>
            <div id="photos" class="tab-pane fade photos">
                <div v-if="employer.photos?.length || 0" class="row mt-5">
                    <div class="col-md-3 photo_inner" v-for="photo in employer.photos" :key="photo.id">
                        <img :src="photo.file?.path || '#'" style="width: 100%" class="hover-shadow cursor" />
                        <p>{{ photo.caption }}</p>
                    </div>
                </div>
                <div v-else class="row justify-content-center mt-3">
                    <empty title="Gallery Empty" />
                </div>
            </div>
            <div id="announcements" class="tab-pane fade">
                <div v-if="employer.announcements?.length || 0">
                    <announcement-card
                        v-for="announcement in employer.announcements"
                        :key="announcement.id"
                        :announcement="announcement"
                        :isAccount="false"
                    />
                </div>
                <div v-else class="row justify-content-center mt-3">
                    <empty title="No Announcement Yet" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .min-w-100 {
        min-width: 100%;
    }
</style>

<script>
    import AwardCard from "@/components/employers/AwardCard.vue";
    import AnnouncementCard from "@/components/employers/AnnouncementCard.vue";
    import OpportunityListViewCard from "@/components/opportunities/OpportunityListViewCard.vue";
    import Empty from "@/components/utils/Empty.vue";

    export default {
        props: ["employer"],

        components: { OpportunityListViewCard, AwardCard, AnnouncementCard, Empty, },
    };
</script>