import { createRouter, createWebHistory } from 'vue-router';
import pagesRoutes from './pages';
import accountRoutes from './account/index';
import subscriptionRoutes from './subscription';

const routes = [
  ...pagesRoutes,
  ...accountRoutes,
  ...subscriptionRoutes,
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, _, next) => {
  if (to.meta.title) document.title = `${to.meta.title} | Job Bank`;
  else document.title = 'Job Bank';
  next();
});

export default router;
