import Jobboard from "../../../services/apis/jobboard";

const state = () => ({
    jobboards: [],
});

const actions = {

    getJobboards: ({ commit }) => {
        Jobboard.getJobboards().then((response) => {
            commit("SET_JOBBOARDS", response.data);
        });
    },

};

const getters = {};

const mutations = {
    SET_JOBBOARDS: (state, data) => {
        state.jobboards = data;
    },

};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
