import Subscription from "../../../services/apis/subscription";

const state = () => ({
    packages: [],
});

const actions = {

    getPackages: ({ commit }) => {
        Subscription.getPackages().then((response) => {
            commit("SET_PACKAGES", response.data);
        });
    },

};

const getters = {};

const mutations = {
    SET_PACKAGES: (state, data) => {
        state.packages = data;
    },

};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
