<template>
    <div>
        <breadcrumb
            :items="[
                {
                    route: '/account/certificates',
                    name: 'Certificates',
                },
                {
                    route: '/account/certificates',
                    name: 'Create Certificate',
                },
            ]"
        />
        <certificate-form :isEdit="false" />
    </div>
</template>

<script>
    import CertificateForm from "./components/CertificateForm.vue";

    export default {
        components: {
            CertificateForm,
        },
    };
</script>